// Suggested usage: https://matplotlib.org/cmocean/#colormap-details
//
// Source: https://github.com/matplotlib/cmocean/tree/2.0/cmocean/rgb
// License information under 'cmocean' entry in VENDORED

export const thermal = [
  [ 0.01555601, 0.13824425, 0.20181089],
  [ 0.01620184, 0.14105074, 0.20897651],
  [ 0.01685649, 0.14382701, 0.21623868],
  [ 0.0175264 , 0.14657173, 0.2235997 ],
  [ 0.01821872, 0.14928346, 0.23106187],
  [ 0.01894138, 0.15196073, 0.23862748],
  [ 0.01969968, 0.15460145, 0.24630497],
  [ 0.02050332, 0.15720378, 0.25409711],
  [ 0.02136721, 0.15976645, 0.26199915],
  [ 0.02230341, 0.16228755, 0.27001321],
  [ 0.0233252 , 0.16476505, 0.27814139],
  [ 0.02444728, 0.16719678, 0.28638573],
  [ 0.02568582, 0.16958042, 0.29474817],
  [ 0.02705867, 0.1719135 , 0.30323056],
  [ 0.02858553, 0.17419338, 0.31183463],
  [ 0.03028808, 0.17641726, 0.32056191],
  [ 0.03219022, 0.17858215, 0.32941369],
  [ 0.03431826, 0.18068487, 0.33839101],
  [ 0.03670118, 0.18272205, 0.34749451],
  [ 0.03937082, 0.18469014, 0.35672441],
  [ 0.04230474, 0.18658537, 0.36608039],
  [ 0.04544128, 0.1884038 , 0.37556146],
  [ 0.04879889, 0.1901413 , 0.38516584],
  [ 0.05238565, 0.19179358, 0.39489082],
  [ 0.05620897, 0.19335621, 0.40473254],
  [ 0.06027561, 0.19482469, 0.41468582],
  [ 0.06459519, 0.19618775, 0.42477146],
  [ 0.06917294, 0.19744583, 0.43495728],
  [ 0.07401398, 0.19859437, 0.44523225],
  [ 0.07912633, 0.19962514, 0.45559656],
  [ 0.08452075, 0.20052842, 0.46605087],
  [ 0.09019392, 0.20130794, 0.47654788],
  [ 0.09616431, 0.20194725, 0.48710445],
  [ 0.1024254 , 0.20245202, 0.49766462],
  [ 0.10899443, 0.20280889, 0.50822709],
  [ 0.11585974, 0.20302735, 0.51872453],
  [ 0.12304243, 0.2030938 , 0.52914838],
  [ 0.13052767, 0.20302178, 0.53942012],
  [ 0.13830991, 0.20281956, 0.54947678],
  [ 0.14637971, 0.20250018, 0.55924613],
  [ 0.15471863, 0.20208507, 0.56864226],
  [ 0.16329705, 0.20160546, 0.57756769],
  [ 0.17207282, 0.20110273, 0.58591838],
  [ 0.18099176, 0.20062705, 0.59359182],
  [ 0.18999022, 0.20023416, 0.60049707],
  [ 0.19899974, 0.19998029, 0.60656515],
  [ 0.20795298, 0.19991643, 0.61175706],
  [ 0.21678952, 0.20008303, 0.61606739],
  [ 0.22546043, 0.20050671, 0.61952279],
  [ 0.23393063, 0.2011992 , 0.62217608],
  [ 0.24217907, 0.20215872, 0.62409793],
  [ 0.25019713, 0.20337273, 0.62536824],
  [ 0.25798611, 0.20482134, 0.62606879],
  [ 0.26555442, 0.20648047, 0.62627797],
  [ 0.27291504, 0.20832446, 0.62606758],
  [ 0.28008339, 0.21032792, 0.62550133],
  [ 0.28706751, 0.21246914, 0.624642  ],
  [ 0.29388514, 0.21472543, 0.62353802],
  [ 0.30055772, 0.21707565, 0.62222519],
  [ 0.30708438, 0.21950598, 0.6207555 ],
  [ 0.31349163, 0.22199831, 0.61914522],
  [ 0.31977984, 0.22454204, 0.61743434],
  [ 0.32596955, 0.22712412, 0.61563293],
  [ 0.33205791, 0.22973736, 0.61377715],
  [ 0.33806601, 0.23237153, 0.61186606],
  [ 0.34399174, 0.23502151, 0.60992807],
  [ 0.34984607, 0.23768089, 0.60796949],
  [ 0.35563846, 0.2403444 , 0.60599535],
  [ 0.36136863, 0.24300892, 0.60402414],
  [ 0.36704345, 0.24567078, 0.60206076],
  [ 0.37267088, 0.2483267 , 0.60010594],
  [ 0.3782548 , 0.25097436, 0.59816563],
  [ 0.38379608, 0.25361228, 0.59624988],
  [ 0.38929883, 0.25623877, 0.5943618 ],
  [ 0.3947691 , 0.25885207, 0.59249964],
  [ 0.40020989, 0.26145107, 0.59066609],
  [ 0.40562409, 0.26403484, 0.58886331],
  [ 0.41101442, 0.26660262, 0.58709291],
  [ 0.41638322, 0.26915383, 0.5853566 ],
  [ 0.42173132, 0.27168825, 0.58365836],
  [ 0.42706343, 0.27420503, 0.5819941 ],
  [ 0.43238181, 0.2767038 , 0.58036393],
  [ 0.43768862, 0.27918426, 0.57876764],
  [ 0.44298597, 0.28164615, 0.5772048 ],
  [ 0.44827587, 0.28408925, 0.57567472],
  [ 0.45356026, 0.28651338, 0.57417648],
  [ 0.45884104, 0.28891838, 0.57270899],
  [ 0.46412001, 0.2913041 , 0.57127095],
  [ 0.46939893, 0.29367044, 0.5698609 ],
  [ 0.47467951, 0.29601729, 0.56847725],
  [ 0.47996336, 0.29834456, 0.56711823],
  [ 0.48525207, 0.30065217, 0.56578195],
  [ 0.49054716, 0.30294008, 0.5644664 ],
  [ 0.49585007, 0.30520822, 0.56316946],
  [ 0.50116221, 0.30745656, 0.56188888],
  [ 0.50648491, 0.30968508, 0.56062233],
  [ 0.51181943, 0.31189376, 0.55936737],
  [ 0.51716699, 0.31408263, 0.55812148],
  [ 0.52252871, 0.31625172, 0.55688205],
  [ 0.52790567, 0.31840106, 0.55564642],
  [ 0.53329886, 0.32053073, 0.55441182],
  [ 0.5387092 , 0.32264084, 0.55317546],
  [ 0.54413753, 0.3247315 , 0.55193448],
  [ 0.54958461, 0.32680287, 0.55068596],
  [ 0.55505113, 0.32885514, 0.54942696],
  [ 0.56053768, 0.33088852, 0.54815451],
  [ 0.56604476, 0.33290327, 0.54686563],
  [ 0.57157236, 0.33489987, 0.54555812],
  [ 0.57712131, 0.33687845, 0.54422801],
  [ 0.58269186, 0.33883938, 0.54287227],
  [ 0.58828416, 0.34078308, 0.54148789],
  [ 0.59389827, 0.34271002, 0.54007187],
  [ 0.59953415, 0.34462069, 0.53862124],
  [ 0.60519167, 0.34651565, 0.53713308],
  [ 0.61087061, 0.34839552, 0.5356045 ],
  [ 0.61657065, 0.35026093, 0.53403268],
  [ 0.62229138, 0.3521126 , 0.53241484],
  [ 0.62803229, 0.35395127, 0.53074831],
  [ 0.63379279, 0.35577776, 0.52903045],
  [ 0.63957219, 0.35759292, 0.52725878],
  [ 0.64536975, 0.35939765, 0.52543076],
  [ 0.6511847 , 0.36119285, 0.52354391],
  [ 0.6570161 , 0.36297956, 0.52159597],
  [ 0.66286293, 0.36475881, 0.51958481],
  [ 0.6687241 , 0.36653173, 0.51750839],
  [ 0.67459845, 0.36829947, 0.5153648 ],
  [ 0.68048472, 0.37006325, 0.51315222],
  [ 0.68638162, 0.37182432, 0.51086898],
  [ 0.69228775, 0.37358401, 0.50851352],
  [ 0.69820166, 0.37534367, 0.50608439],
  [ 0.70412186, 0.37710473, 0.50358028],
  [ 0.71004688, 0.37886859, 0.50099965],
  [ 0.71597525, 0.3806367 , 0.49834106],
  [ 0.72190503, 0.38241075, 0.4956041 ],
  [ 0.72783443, 0.38419239, 0.49278795],
  [ 0.73376164, 0.3859833 , 0.4898919 ],
  [ 0.73968475, 0.38778523, 0.48691538],
  [ 0.7456018 , 0.38960001, 0.48385794],
  [ 0.75151077, 0.39142951, 0.48071927],
  [ 0.75740957, 0.39327566, 0.47749914],
  [ 0.76329604, 0.39514048, 0.4741975 ],
  [ 0.76916794, 0.39702603, 0.47081439],
  [ 0.77502298, 0.39893446, 0.46735   ],
  [ 0.78085877, 0.400868  , 0.46380463],
  [ 0.78667286, 0.40282892, 0.46017874],
  [ 0.79246271, 0.4048196 , 0.45647292],
  [ 0.79822569, 0.40684249, 0.45268792],
  [ 0.80395908, 0.40890011, 0.44882462],
  [ 0.80966007, 0.41099506, 0.44488409],
  [ 0.81532584, 0.41313001, 0.4408672 ],
  [ 0.82095338, 0.41530771, 0.43677537],
  [ 0.82653946, 0.41753108, 0.43261066],
  [ 0.83208085, 0.41980305, 0.42837489],
  [ 0.83757423, 0.42212661, 0.42407011],
  [ 0.84301618, 0.42450482, 0.4196986 ],
  [ 0.84840316, 0.42694082, 0.41526288],
  [ 0.85373155, 0.4294378 , 0.41076573],
  [ 0.8589976 , 0.43199897, 0.40621024],
  [ 0.8641975 , 0.4346276 , 0.40159975],
  [ 0.86932733, 0.43732696, 0.39693796],
  [ 0.87438311, 0.44010033, 0.39222887],
  [ 0.87936081, 0.44295096, 0.38747682],
  [ 0.88425632, 0.44588207, 0.38268654],
  [ 0.88906554, 0.44889681, 0.37786308],
  [ 0.89378437, 0.45199824, 0.3730116 ],
  [ 0.89840867, 0.45518929, 0.36813827],
  [ 0.90293439, 0.45847274, 0.36324942],
  [ 0.90735756, 0.46185117, 0.35835166],
  [ 0.91167433, 0.46532694, 0.35345197],
  [ 0.915881  , 0.46890217, 0.34855767],
  [ 0.91997406, 0.47257868, 0.34367638],
  [ 0.92395023, 0.47635796, 0.33881601],
  [ 0.92780648, 0.48024117, 0.33398471],
  [ 0.93154008, 0.48422909, 0.3291908 ],
  [ 0.93514863, 0.48832213, 0.32444275],
  [ 0.93863008, 0.49252027, 0.31974912],
  [ 0.94198274, 0.49682311, 0.31511847],
  [ 0.94520535, 0.50122981, 0.31055931],
  [ 0.94829701, 0.50573917, 0.30608003],
  [ 0.95125725, 0.51034957, 0.30168887],
  [ 0.954086  , 0.51505906, 0.29739377],
  [ 0.95678355, 0.51986536, 0.2932024 ],
  [ 0.95935057, 0.52476587, 0.28912206],
  [ 0.96178805, 0.52975777, 0.28515964],
  [ 0.9640973 , 0.53483802, 0.28132156],
  [ 0.96627986, 0.54000343, 0.27761379],
  [ 0.9683375 , 0.5452507 , 0.27404177],
  [ 0.97027215, 0.55057645, 0.27061043],
  [ 0.97208896, 0.55597442, 0.26733231],
  [ 0.97378824, 0.56144311, 0.26420514],
  [ 0.97537225, 0.56697917, 0.2612321 ],
  [ 0.97684333, 0.5725793 , 0.2584161 ],
  [ 0.97820376, 0.57824032, 0.25575953],
  [ 0.9794557 , 0.58395922, 0.25326432],
  [ 0.9806012 , 0.58973318, 0.25093197],
  [ 0.98164451, 0.59555764, 0.24876746],
  [ 0.9825958 , 0.60142353, 0.24678389],
  [ 0.98344841, 0.60733543, 0.24496692],
  [ 0.98420378, 0.61329131, 0.24331643],
  [ 0.98486309, 0.61928934, 0.24183204],
  [ 0.98543359, 0.62532321, 0.24052074],
  [ 0.98592661, 0.63138392, 0.2393925 ],
  [ 0.98632908, 0.63748058, 0.2384285 ],
  [ 0.98664126, 0.64361223, 0.23762727],
  [ 0.9868743 , 0.64977022, 0.23699778],
  [ 0.98703872, 0.65594684, 0.236546  ],
  [ 0.98711602, 0.66215449, 0.23625164],
  [ 0.98710548, 0.66839291, 0.23611251],
  [ 0.98704522, 0.67463615, 0.23615516],
  [ 0.98690081, 0.68090692, 0.23634896],
  [ 0.98666929, 0.68720645, 0.23669008],
  [ 0.98639295, 0.6935072 , 0.23720252],
  [ 0.98603581, 0.69983201, 0.23785913],
  [ 0.98559369, 0.70618283, 0.23865535],
  [ 0.98511668, 0.71252856, 0.23961378],
  [ 0.98455154, 0.71890163, 0.24070275],
  [ 0.98392192, 0.72528731, 0.2419305 ],
  [ 0.98324116, 0.73167761, 0.24329811],
  [ 0.98246864, 0.738096  , 0.2447854 ],
  [ 0.98166755, 0.74450581, 0.24641186],
  [ 0.98078113, 0.75093941, 0.24815276],
  [ 0.97983772, 0.75738047, 0.25001371],
  [ 0.97883927, 0.7638279 , 0.25199054],
  [ 0.97775747, 0.7702969 , 0.25407166],
  [ 0.97664794, 0.77675738, 0.25626641],
  [ 0.9754357 , 0.78324913, 0.25855344],
  [ 0.9742107 , 0.7897247 , 0.26094721],
  [ 0.97288357, 0.79623051, 0.2634262 ],
  [ 0.97152974, 0.80272764, 0.26599948],
  [ 0.97008613, 0.80924798, 0.2686529 ],
  [ 0.96860571, 0.81576486, 0.27138993],
  [ 0.96704307, 0.82230061, 0.27420073],
  [ 0.96543772, 0.82883583, 0.27708584],
  [ 0.96375242, 0.83538829, 0.28003763],
  [ 0.96202315, 0.84194079, 0.28305544],
  [ 0.96021049, 0.84851164, 0.28613254],
  [ 0.95835763, 0.85508072, 0.28926815],
  [ 0.95641175, 0.86167196, 0.29245571],
  [ 0.95443484, 0.8682573 , 0.29569479],
  [ 0.95234871, 0.87487125, 0.29897882],
  [ 0.95024646, 0.88147281, 0.30230776],
  [ 0.94801413, 0.88811109, 0.3056752 ],
  [ 0.94578198, 0.89473018, 0.30908112],
  [ 0.94341986, 0.90138491, 0.31252002],
  [ 0.94102862, 0.90803285, 0.31599064],
  [ 0.93853313, 0.91470475, 0.31948933],
  [ 0.93597111, 0.92138481, 0.32301384],
  [ 0.9333376 , 0.92807483, 0.32656155],
  [ 0.93059157, 0.93479051, 0.33012998],
  [ 0.92781425, 0.94149986, 0.33371683],
  [ 0.92488992, 0.94824702, 0.33731997],
  [ 0.92194114, 0.95498491, 0.34093705],
  [ 0.91886139, 0.96175325, 0.3445663 ],
  [ 0.91569318, 0.96853549, 0.34820569],
  [ 0.91244907, 0.97532669, 0.35185336],
  [ 0.90904184, 0.98215741, 0.35550781]
]

export const ice = [
  [ 0.01531167, 0.02252059, 0.07272874],
  [ 0.0180055 , 0.02544552, 0.07841879],
  [ 0.02090133, 0.02852652, 0.08407772],
  [ 0.02399819, 0.03176264, 0.0897075 ],
  [ 0.02729776, 0.03514909, 0.09532647],
  [ 0.03080428, 0.03867708, 0.10096627],
  [ 0.03450984, 0.04229956, 0.10658282],
  [ 0.0384136 , 0.04587404, 0.11217763],
  [ 0.0424579 , 0.04939964, 0.11779608],
  [ 0.04645778, 0.0528849 , 0.1234176 ],
  [ 0.05041622, 0.05633789, 0.12902168],
  [ 0.0543378 , 0.05975738, 0.13462392],
  [ 0.05823   , 0.06313586, 0.14026517],
  [ 0.06208647, 0.06648866, 0.14589217],
  [ 0.06590891, 0.0698176 , 0.15150562],
  [ 0.0697067 , 0.0731091 , 0.15717011],
  [ 0.07347361, 0.07637816, 0.16283083],
  [ 0.07720982, 0.0796282 , 0.16848004],
  [ 0.08092191, 0.08284857, 0.17416973],
  [ 0.08460723, 0.0860472 , 0.17987258],
  [ 0.08826408, 0.08923087, 0.18556525],
  [ 0.09189749, 0.09238992, 0.19129533],
  [ 0.09550606, 0.09552937, 0.19704663],
  [ 0.0990876 , 0.09865736, 0.20278851],
  [ 0.10264643, 0.10176328, 0.2085729 ],
  [ 0.10618075, 0.1048534 , 0.21437716],
  [ 0.1096889 , 0.10793517, 0.22017219],
  [ 0.11317482, 0.11099513, 0.22602364],
  [ 0.11663557, 0.11404484, 0.23188363],
  [ 0.12007068, 0.11708837, 0.23773723],
  [ 0.12348311, 0.12010958, 0.24366409],
  [ 0.12686948, 0.1231272 , 0.24958092],
  [ 0.13023079, 0.12613483, 0.25552177],
  [ 0.13356695, 0.12912934, 0.26150605],
  [ 0.13687687, 0.13212284, 0.26747923],
  [ 0.14016072, 0.13510078, 0.27351646],
  [ 0.14341772, 0.13807648, 0.27955643],
  [ 0.14664749, 0.14104684, 0.28561838],
  [ 0.14984897, 0.14400828, 0.29172467],
  [ 0.15302294, 0.14697296, 0.29781705],
  [ 0.15616589, 0.14992378, 0.30398848],
  [ 0.15928037, 0.15287968, 0.31014411],
  [ 0.16236275, 0.15582876, 0.31635122],
  [ 0.16541424, 0.15877962, 0.32256801],
  [ 0.1684328 , 0.16172931, 0.32881489],
  [ 0.17141745, 0.16467906, 0.33508977],
  [ 0.17436826, 0.16763191, 0.34137988],
  [ 0.1772819 , 0.17058464, 0.34770888],
  [ 0.18016036, 0.17354351, 0.35404476],
  [ 0.18299849, 0.17650348, 0.36042297],
  [ 0.18579938, 0.17947155, 0.36680604],
  [ 0.18855724, 0.18244322, 0.37322757],
  [ 0.19127464, 0.18542401, 0.37965796],
  [ 0.19394722, 0.188412  , 0.38611587],
  [ 0.19657462, 0.19140947, 0.39259226],
  [ 0.19915672, 0.19441854, 0.39907853],
  [ 0.20168703, 0.19743702, 0.40559795],
  [ 0.20417332, 0.2004721 , 0.41210346],
  [ 0.20659892, 0.20351633, 0.41866115],
  [ 0.208977  , 0.20657923, 0.42520322],
  [ 0.21129685, 0.20965752, 0.43176498],
  [ 0.21355801, 0.2127534 , 0.43833741],
  [ 0.21576549, 0.2158706 , 0.44489464],
  [ 0.21790275, 0.21900569, 0.45148217],
  [ 0.21998016, 0.22216408, 0.45805652],
  [ 0.22199703, 0.22534707, 0.46461293],
  [ 0.22393594, 0.22855285, 0.47119005],
  [ 0.22580933, 0.23178597, 0.47774457],
  [ 0.22761504, 0.23504748, 0.48427492],
  [ 0.22934111, 0.23833757, 0.49080012],
  [ 0.23099051, 0.24165846, 0.49730374],
  [ 0.23256539, 0.24501182, 0.50377332],
  [ 0.23406342, 0.2483988 , 0.5102062 ],
  [ 0.23547394, 0.25182056, 0.51661336],
  [ 0.23680103, 0.25527869, 0.52297876],
  [ 0.23804591, 0.25877435, 0.529293  ],
  [ 0.23920692, 0.26230862, 0.53555174],
  [ 0.24028265, 0.26588249, 0.54175028],
  [ 0.24126904, 0.26949715, 0.54788707],
  [ 0.24216465, 0.27315361, 0.55395673],
  [ 0.24297377, 0.2768522 , 0.55994789],
  [ 0.24369643, 0.2805934 , 0.56585499],
  [ 0.24433314, 0.28437755, 0.5716724 ],
  [ 0.2448849 , 0.28820479, 0.57739441],
  [ 0.24535323, 0.29207505, 0.5830154 ],
  [ 0.24574017, 0.29598806, 0.58852986],
  [ 0.24604835, 0.29994331, 0.59393251],
  [ 0.24628095, 0.30394008, 0.59921837],
  [ 0.2464417 , 0.30797739, 0.60438286],
  [ 0.2465349 , 0.31205404, 0.60942183],
  [ 0.24656536, 0.31616862, 0.61433167],
  [ 0.2465384 , 0.32031951, 0.61910933],
  [ 0.24645977, 0.32450489, 0.6237524 ],
  [ 0.24633566, 0.32872279, 0.62825909],
  [ 0.24617258, 0.33297109, 0.6326283 ],
  [ 0.24597735, 0.33724755, 0.63685958],
  [ 0.24575701, 0.34154984, 0.64095315],
  [ 0.24551877, 0.34587559, 0.64490987],
  [ 0.24526992, 0.35022239, 0.64873121],
  [ 0.24501778, 0.35458782, 0.65241923],
  [ 0.24476962, 0.35896949, 0.6559765 ],
  [ 0.24453264, 0.36336505, 0.65940606],
  [ 0.24431385, 0.36777225, 0.66271137],
  [ 0.24412005, 0.37218889, 0.66589627],
  [ 0.2439578 , 0.37661289, 0.66896489],
  [ 0.24383334, 0.38104229, 0.6719216 ],
  [ 0.24375272, 0.3854752 , 0.67477099],
  [ 0.24372159, 0.3899099 , 0.67751781],
  [ 0.24374497, 0.39434483, 0.68016682],
  [ 0.24382759, 0.39877858, 0.68272286],
  [ 0.24397378, 0.40320985, 0.68519075],
  [ 0.24418744, 0.40763748, 0.68757528],
  [ 0.24447207, 0.41206045, 0.68988119],
  [ 0.24483074, 0.41647785, 0.6921131 ],
  [ 0.24526613, 0.4208889 , 0.69427555],
  [ 0.24578055, 0.42529292, 0.69637292],
  [ 0.24637589, 0.42968935, 0.69840946],
  [ 0.24705372, 0.43407771, 0.70038927],
  [ 0.24781523, 0.43845764, 0.70231628],
  [ 0.2486613 , 0.44282884, 0.70419426],
  [ 0.24959252, 0.44719108, 0.70602681],
  [ 0.25060917, 0.45154422, 0.70781736],
  [ 0.25171129, 0.45588817, 0.70956918],
  [ 0.25289865, 0.4602229 , 0.71128535],
  [ 0.25417082, 0.46454843, 0.71296882],
  [ 0.25552716, 0.46886482, 0.71462235],
  [ 0.25696687, 0.47317219, 0.71624858],
  [ 0.25848896, 0.47747067, 0.71784998],
  [ 0.26009232, 0.48176042, 0.71942887],
  [ 0.2617757 , 0.48604166, 0.72098746],
  [ 0.26353778, 0.49031459, 0.7225278 ],
  [ 0.26537711, 0.49457945, 0.72405184],
  [ 0.26729219, 0.49883651, 0.7255614 ],
  [ 0.26928146, 0.50308602, 0.72705818],
  [ 0.27134332, 0.50732827, 0.72854378],
  [ 0.27347612, 0.51156355, 0.73001971],
  [ 0.27567821, 0.51579214, 0.73148736],
  [ 0.27794793, 0.52001436, 0.73294804],
  [ 0.2802836 , 0.52423051, 0.73440296],
  [ 0.28268357, 0.52844088, 0.73585328],
  [ 0.28514622, 0.53264579, 0.73730005],
  [ 0.28766991, 0.53684554, 0.73874426],
  [ 0.29025309, 0.54104043, 0.74018682],
  [ 0.29289419, 0.54523076, 0.74162858],
  [ 0.29559174, 0.54941683, 0.74307033],
  [ 0.29834428, 0.55359893, 0.74451279],
  [ 0.30115042, 0.55777733, 0.74595664],
  [ 0.30400882, 0.56195234, 0.74740249],
  [ 0.3069182 , 0.5661242 , 0.74885089],
  [ 0.30987735, 0.57029319, 0.75030237],
  [ 0.31288513, 0.57445957, 0.75175739],
  [ 0.31594045, 0.57862358, 0.75321637],
  [ 0.31904231, 0.58278547, 0.7546797 ],
  [ 0.32218978, 0.58694546, 0.7561477 ],
  [ 0.325382  , 0.59110377, 0.75762068],
  [ 0.32861818, 0.5952606 , 0.7590989 ],
  [ 0.33189762, 0.59941617, 0.76058259],
  [ 0.3352197 , 0.60357064, 0.76207194],
  [ 0.33858388, 0.60772419, 0.76356711],
  [ 0.34198969, 0.61187699, 0.76506822],
  [ 0.34543677, 0.61602918, 0.76657539],
  [ 0.34892482, 0.62018088, 0.76808867],
  [ 0.35245366, 0.62433221, 0.76960811],
  [ 0.35602315, 0.62848328, 0.77113373],
  [ 0.3596333 , 0.63263416, 0.77266552],
  [ 0.36328417, 0.63678492, 0.77420346],
  [ 0.36697594, 0.64093562, 0.7757475 ],
  [ 0.37070887, 0.64508626, 0.77729756],
  [ 0.37448333, 0.64923687, 0.77885356],
  [ 0.37829979, 0.65338743, 0.78041541],
  [ 0.38215883, 0.6575379 , 0.78198297],
  [ 0.38606113, 0.66168821, 0.78355612],
  [ 0.39000748, 0.66583833, 0.78513436],
  [ 0.39399882, 0.66998823, 0.78671676],
  [ 0.39803619, 0.67413764, 0.78830412],
  [ 0.40212077, 0.6782864 , 0.78989626],
  [ 0.40625381, 0.68243429, 0.79149303],
  [ 0.41043672, 0.68658107, 0.79309427],
  [ 0.41467103, 0.69072647, 0.79469983],
  [ 0.4189584 , 0.69487019, 0.79630958],
  [ 0.42330076, 0.69901195, 0.79792237],
  [ 0.42770031, 0.70315144, 0.79953693],
  [ 0.43215888, 0.70728805, 0.80115515],
  [ 0.43667866, 0.7114213 , 0.802777  ],
  [ 0.44126199, 0.71555064, 0.80440251],
  [ 0.44591175, 0.71967561, 0.80603022],
  [ 0.4506311 , 0.72379561, 0.8076587 ],
  [ 0.45542205, 0.72790979, 0.80929102],
  [ 0.46028743, 0.73201742, 0.8109275 ],
  [ 0.46523129, 0.7361178 , 0.81256547],
  [ 0.47025671, 0.74021004, 0.81420586],
  [ 0.47536584, 0.74429316, 0.81585182],
  [ 0.480563  , 0.74836626, 0.81750143],
  [ 0.48585194, 0.75242831, 0.81915472],
  [ 0.49123401, 0.75647827, 0.82081642],
  [ 0.49671496, 0.76051503, 0.82248288],
  [ 0.50229586, 0.76453755, 0.82415903],
  [ 0.5079798 , 0.76854472, 0.82584588],
  [ 0.51377031, 0.77253541, 0.82754379],
  [ 0.51966727, 0.77650874, 0.82925804],
  [ 0.52567488, 0.78046351, 0.83098749],
  [ 0.53179094, 0.78439917, 0.83273894],
  [ 0.5380184 , 0.78831467, 0.8345121 ],
  [ 0.544354  , 0.79220975, 0.83631331],
  [ 0.55079789, 0.79608381, 0.83814427],
  [ 0.55734648, 0.79993683, 0.84000992],
  [ 0.56399647, 0.80376887, 0.84191391],
  [ 0.57074395, 0.80758021, 0.84385969],
  [ 0.57758278, 0.81137158, 0.84585184],
  [ 0.58450804, 0.81514366, 0.84789305],
  [ 0.59151241, 0.81889765, 0.84998727],
  [ 0.59858923, 0.8226348 , 0.85213717],
  [ 0.60573106, 0.8263566 , 0.85434539],
  [ 0.6129312 , 0.83006454, 0.85661357],
  [ 0.62018101, 0.8337606 , 0.85894417],
  [ 0.62747522, 0.8374462 , 0.86133712],
  [ 0.63480525, 0.84112351, 0.8637942 ],
  [ 0.64216625, 0.84479403, 0.86631468],
  [ 0.64955148, 0.84845972, 0.86889888],
  [ 0.65695573, 0.85212228, 0.87154615],
  [ 0.66437458, 0.85578326, 0.87425541],
  [ 0.67180273, 0.85944449, 0.87702608],
  [ 0.67923764, 0.86310717, 0.87985618],
  [ 0.6866745 , 0.86677306, 0.88274497],
  [ 0.69411182, 0.87044318, 0.88569014],
  [ 0.70154587, 0.87411906, 0.88869057],
  [ 0.70897547, 0.87780166, 0.89174404],
  [ 0.71639823, 0.88149221, 0.89484897],
  [ 0.72381291, 0.88519169, 0.89800335],
  [ 0.7312183 , 0.88890104, 0.90120526],
  [ 0.73861316, 0.89262124, 0.90445285],
  [ 0.74599695, 0.89635308, 0.90774403],
  [ 0.7533689 , 0.90009739, 0.91107686],
  [ 0.76072821, 0.90385503, 0.91444944],
  [ 0.76807541, 0.9076265 , 0.91785934],
  [ 0.77540864, 0.91141295, 0.92130508],
  [ 0.78272987, 0.91521448, 0.92478363],
  [ 0.79003624, 0.91903253, 0.92829379],
  [ 0.79733058, 0.92286698, 0.93183206],
  [ 0.80460936, 0.9267195 , 0.93539728],
  [ 0.81187625, 0.93058973, 0.93898531],
  [ 0.81912624, 0.93447982, 0.9425952 ],
  [ 0.82636495, 0.9383889 , 0.94622154],
  [ 0.83358579, 0.94231965, 0.94986338],
  [ 0.84079256, 0.9462718 , 0.9535152 ],
  [ 0.84798207, 0.95024718, 0.95717374],
  [ 0.85515095, 0.95424775, 0.96083521],
  [ 0.86230259, 0.95827357, 0.96449203],
  [ 0.86942981, 0.96232795, 0.96814019],
  [ 0.87652837, 0.96641348, 0.9717733 ],
  [ 0.88359326, 0.97053314, 0.97538393],
  [ 0.89061741, 0.97469071, 0.9789641 ],
  [ 0.89759175, 0.9788908 , 0.98250515],
  [ 0.90450134, 0.9831399 , 0.98600058],
  [ 0.91133005, 0.98744495, 0.98944426],
  [ 0.9180593 , 0.99181354, 0.99283286]
];
