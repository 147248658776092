// Suggested usage: https://seaborn.pydata.org/tutorial/color_palettes.html
//
// Source: https://github.com/mwaskom/seaborn/blob/v0.11.1/seaborn/cm.py
// License information under 'seaborn' entry in VENDORED

export const rocket = [
    [ 0.01060815, 0.01808215, 0.10018654],
    [ 0.01428972, 0.02048237, 0.10374486],
    [ 0.01831941, 0.0229766 , 0.10738511],
    [ 0.02275049, 0.02554464, 0.11108639],
    [ 0.02759119, 0.02818316, 0.11483751],
    [ 0.03285175, 0.03088792, 0.11863035],
    [ 0.03853466, 0.03365771, 0.12245873],
    [ 0.04447016, 0.03648425, 0.12631831],
    [ 0.05032105, 0.03936808, 0.13020508],
    [ 0.05611171, 0.04224835, 0.13411624],
    [ 0.0618531 , 0.04504866, 0.13804929],
    [ 0.06755457, 0.04778179, 0.14200206],
    [ 0.0732236 , 0.05045047, 0.14597263],
    [ 0.0788708 , 0.05305461, 0.14995981],
    [ 0.08450105, 0.05559631, 0.15396203],
    [ 0.09011319, 0.05808059, 0.15797687],
    [ 0.09572396, 0.06050127, 0.16200507],
    [ 0.10132312, 0.06286782, 0.16604287],
    [ 0.10692823, 0.06517224, 0.17009175],
    [ 0.1125315 , 0.06742194, 0.17414848],
    [ 0.11813947, 0.06961499, 0.17821272],
    [ 0.12375803, 0.07174938, 0.18228425],
    [ 0.12938228, 0.07383015, 0.18636053],
    [ 0.13501631, 0.07585609, 0.19044109],
    [ 0.14066867, 0.0778224 , 0.19452676],
    [ 0.14633406, 0.07973393, 0.1986151 ],
    [ 0.15201338, 0.08159108, 0.20270523],
    [ 0.15770877, 0.08339312, 0.20679668],
    [ 0.16342174, 0.0851396 , 0.21088893],
    [ 0.16915387, 0.08682996, 0.21498104],
    [ 0.17489524, 0.08848235, 0.2190294 ],
    [ 0.18065495, 0.09009031, 0.22303512],
    [ 0.18643324, 0.09165431, 0.22699705],
    [ 0.19223028, 0.09317479, 0.23091409],
    [ 0.19804623, 0.09465217, 0.23478512],
    [ 0.20388117, 0.09608689, 0.23860907],
    [ 0.20973515, 0.09747934, 0.24238489],
    [ 0.21560818, 0.09882993, 0.24611154],
    [ 0.22150014, 0.10013944, 0.2497868 ],
    [ 0.22741085, 0.10140876, 0.25340813],
    [ 0.23334047, 0.10263737, 0.25697736],
    [ 0.23928891, 0.10382562, 0.2604936 ],
    [ 0.24525608, 0.10497384, 0.26395596],
    [ 0.25124182, 0.10608236, 0.26736359],
    [ 0.25724602, 0.10715148, 0.27071569],
    [ 0.26326851, 0.1081815 , 0.27401148],
    [ 0.26930915, 0.1091727 , 0.2772502 ],
    [ 0.27536766, 0.11012568, 0.28043021],
    [ 0.28144375, 0.11104133, 0.2835489 ],
    [ 0.2875374 , 0.11191896, 0.28660853],
    [ 0.29364846, 0.11275876, 0.2896085 ],
    [ 0.29977678, 0.11356089, 0.29254823],
    [ 0.30592213, 0.11432553, 0.29542718],
    [ 0.31208435, 0.11505284, 0.29824485],
    [ 0.31826327, 0.1157429 , 0.30100076],
    [ 0.32445869, 0.11639585, 0.30369448],
    [ 0.33067031, 0.11701189, 0.30632563],
    [ 0.33689808, 0.11759095, 0.3088938 ],
    [ 0.34314168, 0.11813362, 0.31139721],
    [ 0.34940101, 0.11863987, 0.3138355 ],
    [ 0.355676  , 0.11910909, 0.31620996],
    [ 0.36196644, 0.1195413 , 0.31852037],
    [ 0.36827206, 0.11993653, 0.32076656],
    [ 0.37459292, 0.12029443, 0.32294825],
    [ 0.38092887, 0.12061482, 0.32506528],
    [ 0.38727975, 0.12089756, 0.3271175 ],
    [ 0.39364518, 0.12114272, 0.32910494],
    [ 0.40002537, 0.12134964, 0.33102734],
    [ 0.40642019, 0.12151801, 0.33288464],
    [ 0.41282936, 0.12164769, 0.33467689],
    [ 0.41925278, 0.12173833, 0.33640407],
    [ 0.42569057, 0.12178916, 0.33806605],
    [ 0.43214263, 0.12179973, 0.33966284],
    [ 0.43860848, 0.12177004, 0.34119475],
    [ 0.44508855, 0.12169883, 0.34266151],
    [ 0.45158266, 0.12158557, 0.34406324],
    [ 0.45809049, 0.12142996, 0.34540024],
    [ 0.46461238, 0.12123063, 0.34667231],
    [ 0.47114798, 0.12098721, 0.34787978],
    [ 0.47769736, 0.12069864, 0.34902273],
    [ 0.48426077, 0.12036349, 0.35010104],
    [ 0.49083761, 0.11998161, 0.35111537],
    [ 0.49742847, 0.11955087, 0.35206533],
    [ 0.50403286, 0.11907081, 0.35295152],
    [ 0.51065109, 0.11853959, 0.35377385],
    [ 0.51728314, 0.1179558 , 0.35453252],
    [ 0.52392883, 0.11731817, 0.35522789],
    [ 0.53058853, 0.11662445, 0.35585982],
    [ 0.53726173, 0.11587369, 0.35642903],
    [ 0.54394898, 0.11506307, 0.35693521],
    [ 0.5506426 , 0.11420757, 0.35737863],
    [ 0.55734473, 0.11330456, 0.35775059],
    [ 0.56405586, 0.11235265, 0.35804813],
    [ 0.57077365, 0.11135597, 0.35827146],
    [ 0.5774991 , 0.11031233, 0.35841679],
    [ 0.58422945, 0.10922707, 0.35848469],
    [ 0.59096382, 0.10810205, 0.35847347],
    [ 0.59770215, 0.10693774, 0.35838029],
    [ 0.60444226, 0.10573912, 0.35820487],
    [ 0.61118304, 0.10450943, 0.35794557],
    [ 0.61792306, 0.10325288, 0.35760108],
    [ 0.62466162, 0.10197244, 0.35716891],
    [ 0.63139686, 0.10067417, 0.35664819],
    [ 0.63812122, 0.09938212, 0.35603757],
    [ 0.64483795, 0.0980891 , 0.35533555],
    [ 0.65154562, 0.09680192, 0.35454107],
    [ 0.65824241, 0.09552918, 0.3536529 ],
    [ 0.66492652, 0.09428017, 0.3526697 ],
    [ 0.67159578, 0.09306598, 0.35159077],
    [ 0.67824099, 0.09192342, 0.3504148 ],
    [ 0.684863  , 0.09085633, 0.34914061],
    [ 0.69146268, 0.0898675 , 0.34776864],
    [ 0.69803757, 0.08897226, 0.3462986 ],
    [ 0.70457834, 0.0882129 , 0.34473046],
    [ 0.71108138, 0.08761223, 0.3430635 ],
    [ 0.7175507 , 0.08716212, 0.34129974],
    [ 0.72398193, 0.08688725, 0.33943958],
    [ 0.73035829, 0.0868623 , 0.33748452],
    [ 0.73669146, 0.08704683, 0.33543669],
    [ 0.74297501, 0.08747196, 0.33329799],
    [ 0.74919318, 0.08820542, 0.33107204],
    [ 0.75535825, 0.08919792, 0.32876184],
    [ 0.76145589, 0.09050716, 0.32637117],
    [ 0.76748424, 0.09213602, 0.32390525],
    [ 0.77344838, 0.09405684, 0.32136808],
    [ 0.77932641, 0.09634794, 0.31876642],
    [ 0.78513609, 0.09892473, 0.31610488],
    [ 0.79085854, 0.10184672, 0.313391  ],
    [ 0.7965014 , 0.10506637, 0.31063031],
    [ 0.80205987, 0.10858333, 0.30783   ],
    [ 0.80752799, 0.11239964, 0.30499738],
    [ 0.81291606, 0.11645784, 0.30213802],
    [ 0.81820481, 0.12080606, 0.29926105],
    [ 0.82341472, 0.12535343, 0.2963705 ],
    [ 0.82852822, 0.13014118, 0.29347474],
    [ 0.83355779, 0.13511035, 0.29057852],
    [ 0.83850183, 0.14025098, 0.2876878 ],
    [ 0.84335441, 0.14556683, 0.28480819],
    [ 0.84813096, 0.15099892, 0.281943  ],
    [ 0.85281737, 0.15657772, 0.27909826],
    [ 0.85742602, 0.1622583 , 0.27627462],
    [ 0.86196552, 0.16801239, 0.27346473],
    [ 0.86641628, 0.17387796, 0.27070818],
    [ 0.87079129, 0.17982114, 0.26797378],
    [ 0.87507281, 0.18587368, 0.26529697],
    [ 0.87925878, 0.19203259, 0.26268136],
    [ 0.8833417 , 0.19830556, 0.26014181],
    [ 0.88731387, 0.20469941, 0.25769539],
    [ 0.89116859, 0.21121788, 0.2553592 ],
    [ 0.89490337, 0.21785614, 0.25314362],
    [ 0.8985026 , 0.22463251, 0.25108745],
    [ 0.90197527, 0.23152063, 0.24918223],
    [ 0.90530097, 0.23854541, 0.24748098],
    [ 0.90848638, 0.24568473, 0.24598324],
    [ 0.911533  , 0.25292623, 0.24470258],
    [ 0.9144225 , 0.26028902, 0.24369359],
    [ 0.91717106, 0.26773821, 0.24294137],
    [ 0.91978131, 0.27526191, 0.24245973],
    [ 0.92223947, 0.28287251, 0.24229568],
    [ 0.92456587, 0.29053388, 0.24242622],
    [ 0.92676657, 0.29823282, 0.24285536],
    [ 0.92882964, 0.30598085, 0.24362274],
    [ 0.93078135, 0.31373977, 0.24468803],
    [ 0.93262051, 0.3215093 , 0.24606461],
    [ 0.93435067, 0.32928362, 0.24775328],
    [ 0.93599076, 0.33703942, 0.24972157],
    [ 0.93752831, 0.34479177, 0.25199928],
    [ 0.93899289, 0.35250734, 0.25452808],
    [ 0.94036561, 0.36020899, 0.25734661],
    [ 0.94167588, 0.36786594, 0.2603949 ],
    [ 0.94291042, 0.37549479, 0.26369821],
    [ 0.94408513, 0.3830811 , 0.26722004],
    [ 0.94520419, 0.39062329, 0.27094924],
    [ 0.94625977, 0.39813168, 0.27489742],
    [ 0.94727016, 0.4055909 , 0.27902322],
    [ 0.94823505, 0.41300424, 0.28332283],
    [ 0.94914549, 0.42038251, 0.28780969],
    [ 0.95001704, 0.42771398, 0.29244728],
    [ 0.95085121, 0.43500005, 0.29722817],
    [ 0.95165009, 0.44224144, 0.30214494],
    [ 0.9524044 , 0.44944853, 0.3072105 ],
    [ 0.95312556, 0.45661389, 0.31239776],
    [ 0.95381595, 0.46373781, 0.31769923],
    [ 0.95447591, 0.47082238, 0.32310953],
    [ 0.95510255, 0.47787236, 0.32862553],
    [ 0.95569679, 0.48489115, 0.33421404],
    [ 0.95626788, 0.49187351, 0.33985601],
    [ 0.95681685, 0.49882008, 0.34555431],
    [ 0.9573439 , 0.50573243, 0.35130912],
    [ 0.95784842, 0.51261283, 0.35711942],
    [ 0.95833051, 0.51946267, 0.36298589],
    [ 0.95879054, 0.52628305, 0.36890904],
    [ 0.95922872, 0.53307513, 0.3748895 ],
    [ 0.95964538, 0.53983991, 0.38092784],
    [ 0.96004345, 0.54657593, 0.3870292 ],
    [ 0.96042097, 0.55328624, 0.39319057],
    [ 0.96077819, 0.55997184, 0.39941173],
    [ 0.9611152 , 0.5666337 , 0.40569343],
    [ 0.96143273, 0.57327231, 0.41203603],
    [ 0.96173392, 0.57988594, 0.41844491],
    [ 0.96201757, 0.58647675, 0.42491751],
    [ 0.96228344, 0.59304598, 0.43145271],
    [ 0.96253168, 0.5995944 , 0.43805131],
    [ 0.96276513, 0.60612062, 0.44471698],
    [ 0.96298491, 0.6126247 , 0.45145074],
    [ 0.96318967, 0.61910879, 0.45824902],
    [ 0.96337949, 0.6255736 , 0.46511271],
    [ 0.96355923, 0.63201624, 0.47204746],
    [ 0.96372785, 0.63843852, 0.47905028],
    [ 0.96388426, 0.64484214, 0.4861196 ],
    [ 0.96403203, 0.65122535, 0.4932578 ],
    [ 0.96417332, 0.65758729, 0.50046894],
    [ 0.9643063 , 0.66393045, 0.5077467 ],
    [ 0.96443322, 0.67025402, 0.51509334],
    [ 0.96455845, 0.67655564, 0.52251447],
    [ 0.96467922, 0.68283846, 0.53000231],
    [ 0.96479861, 0.68910113, 0.53756026],
    [ 0.96492035, 0.69534192, 0.5451917 ],
    [ 0.96504223, 0.7015636 , 0.5528892 ],
    [ 0.96516917, 0.70776351, 0.5606593 ],
    [ 0.96530224, 0.71394212, 0.56849894],
    [ 0.96544032, 0.72010124, 0.57640375],
    [ 0.96559206, 0.72623592, 0.58438387],
    [ 0.96575293, 0.73235058, 0.59242739],
    [ 0.96592829, 0.73844258, 0.60053991],
    [ 0.96612013, 0.74451182, 0.60871954],
    [ 0.96632832, 0.75055966, 0.61696136],
    [ 0.96656022, 0.75658231, 0.62527295],
    [ 0.96681185, 0.76258381, 0.63364277],
    [ 0.96709183, 0.76855969, 0.64207921],
    [ 0.96739773, 0.77451297, 0.65057302],
    [ 0.96773482, 0.78044149, 0.65912731],
    [ 0.96810471, 0.78634563, 0.66773889],
    [ 0.96850919, 0.79222565, 0.6764046 ],
    [ 0.96893132, 0.79809112, 0.68512266],
    [ 0.96935926, 0.80395415, 0.69383201],
    [ 0.9698028 , 0.80981139, 0.70252255],
    [ 0.97025511, 0.81566605, 0.71120296],
    [ 0.97071849, 0.82151775, 0.71987163],
    [ 0.97120159, 0.82736371, 0.72851999],
    [ 0.97169389, 0.83320847, 0.73716071],
    [ 0.97220061, 0.83905052, 0.74578903],
    [ 0.97272597, 0.84488881, 0.75440141],
    [ 0.97327085, 0.85072354, 0.76299805],
    [ 0.97383206, 0.85655639, 0.77158353],
    [ 0.97441222, 0.86238689, 0.78015619],
    [ 0.97501782, 0.86821321, 0.78871034],
    [ 0.97564391, 0.87403763, 0.79725261],
    [ 0.97628674, 0.87986189, 0.8057883 ],
    [ 0.97696114, 0.88568129, 0.81430324],
    [ 0.97765722, 0.89149971, 0.82280948],
    [ 0.97837585, 0.89731727, 0.83130786],
    [ 0.97912374, 0.90313207, 0.83979337],
    [ 0.979891  , 0.90894778, 0.84827858],
    [ 0.98067764, 0.91476465, 0.85676611],
    [ 0.98137749, 0.92061729, 0.86536915]
]

export const mako = [
    [ 0.04503935, 0.01482344, 0.02092227],
    [ 0.04933018, 0.01709292, 0.02535719],
    [ 0.05356262, 0.01950702, 0.03018802],
    [ 0.05774337, 0.02205989, 0.03545515],
    [ 0.06188095, 0.02474764, 0.04115287],
    [ 0.06598247, 0.0275665 , 0.04691409],
    [ 0.07005374, 0.03051278, 0.05264306],
    [ 0.07409947, 0.03358324, 0.05834631],
    [ 0.07812339, 0.03677446, 0.06403249],
    [ 0.08212852, 0.0400833 , 0.06970862],
    [ 0.08611731, 0.04339148, 0.07538208],
    [ 0.09009161, 0.04664706, 0.08105568],
    [ 0.09405308, 0.04985685, 0.08673591],
    [ 0.09800301, 0.05302279, 0.09242646],
    [ 0.10194255, 0.05614641, 0.09813162],
    [ 0.10587261, 0.05922941, 0.103854  ],
    [ 0.1097942 , 0.06227277, 0.10959847],
    [ 0.11370826, 0.06527747, 0.11536893],
    [ 0.11761516, 0.06824548, 0.12116393],
    [ 0.12151575, 0.07117741, 0.12698763],
    [ 0.12541095, 0.07407363, 0.1328442 ],
    [ 0.12930083, 0.07693611, 0.13873064],
    [ 0.13317849, 0.07976988, 0.14465095],
    [ 0.13701138, 0.08259683, 0.15060265],
    [ 0.14079223, 0.08542126, 0.15659379],
    [ 0.14452486, 0.08824175, 0.16262484],
    [ 0.14820351, 0.09106304, 0.16869476],
    [ 0.15183185, 0.09388372, 0.17480366],
    [ 0.15540398, 0.09670855, 0.18094993],
    [ 0.15892417, 0.09953561, 0.18713384],
    [ 0.16238588, 0.10236998, 0.19335329],
    [ 0.16579435, 0.10520905, 0.19960847],
    [ 0.16914226, 0.10805832, 0.20589698],
    [ 0.17243586, 0.11091443, 0.21221911],
    [ 0.17566717, 0.11378321, 0.21857219],
    [ 0.17884322, 0.11666074, 0.2249565 ],
    [ 0.18195582, 0.11955283, 0.23136943],
    [ 0.18501213, 0.12245547, 0.23781116],
    [ 0.18800459, 0.12537395, 0.24427914],
    [ 0.19093944, 0.1283047 , 0.25077369],
    [ 0.19381092, 0.13125179, 0.25729255],
    [ 0.19662307, 0.13421303, 0.26383543],
    [ 0.19937337, 0.13719028, 0.27040111],
    [ 0.20206187, 0.14018372, 0.27698891],
    [ 0.20469116, 0.14319196, 0.28359861],
    [ 0.20725547, 0.14621882, 0.29022775],
    [ 0.20976258, 0.14925954, 0.29687795],
    [ 0.21220409, 0.15231929, 0.30354703],
    [ 0.21458611, 0.15539445, 0.31023563],
    [ 0.21690827, 0.15848519, 0.31694355],
    [ 0.21916481, 0.16159489, 0.32366939],
    [ 0.2213631 , 0.16471913, 0.33041431],
    [ 0.22349947, 0.1678599 , 0.33717781],
    [ 0.2255714 , 0.1710185 , 0.34395925],
    [ 0.22758415, 0.17419169, 0.35075983],
    [ 0.22953569, 0.17738041, 0.35757941],
    [ 0.23142077, 0.18058733, 0.3644173 ],
    [ 0.2332454 , 0.18380872, 0.37127514],
    [ 0.2350092 , 0.18704459, 0.3781528 ],
    [ 0.23670785, 0.190297  , 0.38504973],
    [ 0.23834119, 0.19356547, 0.39196711],
    [ 0.23991189, 0.19684817, 0.39890581],
    [ 0.24141903, 0.20014508, 0.4058667 ],
    [ 0.24286214, 0.20345642, 0.4128484 ],
    [ 0.24423453, 0.20678459, 0.41985299],
    [ 0.24554109, 0.21012669, 0.42688124],
    [ 0.2467815 , 0.21348266, 0.43393244],
    [ 0.24795393, 0.21685249, 0.4410088 ],
    [ 0.24905614, 0.22023618, 0.448113  ],
    [ 0.25007383, 0.22365053, 0.45519562],
    [ 0.25098926, 0.22710664, 0.46223892],
    [ 0.25179696, 0.23060342, 0.46925447],
    [ 0.25249346, 0.23414353, 0.47623196],
    [ 0.25307401, 0.23772973, 0.48316271],
    [ 0.25353152, 0.24136961, 0.49001976],
    [ 0.25386167, 0.24506548, 0.49679407],
    [ 0.25406082, 0.2488164 , 0.50348932],
    [ 0.25412435, 0.25262843, 0.51007843],
    [ 0.25404842, 0.25650743, 0.51653282],
    [ 0.25383134, 0.26044852, 0.52286845],
    [ 0.2534705 , 0.26446165, 0.52903422],
    [ 0.25296722, 0.2685428 , 0.53503572],
    [ 0.2523226 , 0.27269346, 0.54085315],
    [ 0.25153974, 0.27691629, 0.54645752],
    [ 0.25062402, 0.28120467, 0.55185939],
    [ 0.24958205, 0.28556371, 0.55701246],
    [ 0.24842386, 0.28998148, 0.56194601],
    [ 0.24715928, 0.29446327, 0.56660884],
    [ 0.24580099, 0.29899398, 0.57104399],
    [ 0.24436202, 0.30357852, 0.57519929],
    [ 0.24285591, 0.30819938, 0.57913247],
    [ 0.24129828, 0.31286235, 0.58278615],
    [ 0.23970131, 0.3175495 , 0.5862272 ],
    [ 0.23807973, 0.32226344, 0.58941872],
    [ 0.23644557, 0.32699241, 0.59240198],
    [ 0.2348113 , 0.33173196, 0.59518282],
    [ 0.23318874, 0.33648036, 0.59775543],
    [ 0.2315855 , 0.34122763, 0.60016456],
    [ 0.23001121, 0.34597357, 0.60240251],
    [ 0.2284748 , 0.35071512, 0.6044784 ],
    [ 0.22698081, 0.35544612, 0.60642528],
    [ 0.22553305, 0.36016515, 0.60825252],
    [ 0.22413977, 0.36487341, 0.60994938],
    [ 0.22280246, 0.36956728, 0.61154118],
    [ 0.22152555, 0.37424409, 0.61304472],
    [ 0.22030752, 0.37890437, 0.61446646],
    [ 0.2191538 , 0.38354668, 0.61581561],
    [ 0.21806257, 0.38817169, 0.61709794],
    [ 0.21703799, 0.39277882, 0.61831922],
    [ 0.21607792, 0.39736958, 0.61948028],
    [ 0.21518463, 0.40194196, 0.62059763],
    [ 0.21435467, 0.40649717, 0.62167507],
    [ 0.21358663, 0.41103579, 0.62271724],
    [ 0.21288172, 0.41555771, 0.62373011],
    [ 0.21223835, 0.42006355, 0.62471794],
    [ 0.21165312, 0.42455441, 0.62568371],
    [ 0.21112526, 0.42903064, 0.6266318 ],
    [ 0.21065161, 0.43349321, 0.62756504],
    [ 0.21023306, 0.43794288, 0.62848279],
    [ 0.20985996, 0.44238227, 0.62938329],
    [ 0.20951045, 0.44680966, 0.63030696],
    [ 0.20916709, 0.45122981, 0.63124483],
    [ 0.20882976, 0.45564335, 0.63219599],
    [ 0.20849798, 0.46005094, 0.63315928],
    [ 0.20817199, 0.46445309, 0.63413391],
    [ 0.20785149, 0.46885041, 0.63511876],
    [ 0.20753716, 0.47324327, 0.63611321],
    [ 0.20722876, 0.47763224, 0.63711608],
    [ 0.20692679, 0.48201774, 0.63812656],
    [ 0.20663156, 0.48640018, 0.63914367],
    [ 0.20634336, 0.49078002, 0.64016638],
    [ 0.20606303, 0.49515755, 0.6411939 ],
    [ 0.20578999, 0.49953341, 0.64222457],
    [ 0.20552612, 0.50390766, 0.64325811],
    [ 0.20527189, 0.50828072, 0.64429331],
    [ 0.20502868, 0.51265277, 0.64532947],
    [ 0.20479718, 0.51702417, 0.64636539],
    [ 0.20457804, 0.52139527, 0.64739979],
    [ 0.20437304, 0.52576622, 0.64843198],
    [ 0.20418396, 0.53013715, 0.64946117],
    [ 0.20401238, 0.53450825, 0.65048638],
    [ 0.20385896, 0.53887991, 0.65150606],
    [ 0.20372653, 0.54325208, 0.65251978],
    [ 0.20361709, 0.5476249 , 0.6535266 ],
    [ 0.20353258, 0.55199854, 0.65452542],
    [ 0.20347472, 0.55637318, 0.655515  ],
    [ 0.20344718, 0.56074869, 0.65649508],
    [ 0.20345161, 0.56512531, 0.65746419],
    [ 0.20349089, 0.56950304, 0.65842151],
    [ 0.20356842, 0.57388184, 0.65936642],
    [ 0.20368663, 0.57826181, 0.66029768],
    [ 0.20384884, 0.58264293, 0.6612145 ],
    [ 0.20405904, 0.58702506, 0.66211645],
    [ 0.20431921, 0.59140842, 0.66300179],
    [ 0.20463464, 0.59579264, 0.66387079],
    [ 0.20500731, 0.60017798, 0.66472159],
    [ 0.20544449, 0.60456387, 0.66555409],
    [ 0.20596097, 0.60894927, 0.66636568],
    [ 0.20654832, 0.61333521, 0.66715744],
    [ 0.20721003, 0.61772167, 0.66792838],
    [ 0.20795035, 0.62210845, 0.66867802],
    [ 0.20877302, 0.62649546, 0.66940555],
    [ 0.20968223, 0.63088252, 0.6701105 ],
    [ 0.21068163, 0.63526951, 0.67079211],
    [ 0.21177544, 0.63965621, 0.67145005],
    [ 0.21298582, 0.64404072, 0.67208182],
    [ 0.21430361, 0.64842404, 0.67268861],
    [ 0.21572716, 0.65280655, 0.67326978],
    [ 0.21726052, 0.65718791, 0.6738255 ],
    [ 0.21890636, 0.66156803, 0.67435491],
    [ 0.220668  , 0.66594665, 0.67485792],
    [ 0.22255447, 0.67032297, 0.67533374],
    [ 0.22458372, 0.67469531, 0.67578061],
    [ 0.22673713, 0.67906542, 0.67620044],
    [ 0.22901625, 0.6834332 , 0.67659251],
    [ 0.23142316, 0.68779836, 0.67695703],
    [ 0.23395924, 0.69216072, 0.67729378],
    [ 0.23663857, 0.69651881, 0.67760151],
    [ 0.23946645, 0.70087194, 0.67788018],
    [ 0.24242624, 0.70522162, 0.67813088],
    [ 0.24549008, 0.70957083, 0.67835215],
    [ 0.24863372, 0.71392166, 0.67854868],
    [ 0.25187832, 0.71827158, 0.67872193],
    [ 0.25524083, 0.72261873, 0.67887024],
    [ 0.25870947, 0.72696469, 0.67898912],
    [ 0.26229238, 0.73130855, 0.67907645],
    [ 0.26604085, 0.73564353, 0.67914062],
    [ 0.26993099, 0.73997282, 0.67917264],
    [ 0.27397488, 0.74429484, 0.67917096],
    [ 0.27822463, 0.74860229, 0.67914468],
    [ 0.28264201, 0.75290034, 0.67907959],
    [ 0.2873016 , 0.75717817, 0.67899164],
    [ 0.29215894, 0.76144162, 0.67886578],
    [ 0.29729823, 0.76567816, 0.67871894],
    [ 0.30268199, 0.76989232, 0.67853896],
    [ 0.30835665, 0.77407636, 0.67833512],
    [ 0.31435139, 0.77822478, 0.67811118],
    [ 0.3206671 , 0.78233575, 0.67786729],
    [ 0.32733158, 0.78640315, 0.67761027],
    [ 0.33437168, 0.79042043, 0.67734882],
    [ 0.34182112, 0.79437948, 0.67709394],
    [ 0.34968889, 0.79827511, 0.67685638],
    [ 0.35799244, 0.80210037, 0.67664969],
    [ 0.36675371, 0.80584651, 0.67649539],
    [ 0.3759816 , 0.80950627, 0.67641393],
    [ 0.38566792, 0.81307432, 0.67642947],
    [ 0.39579804, 0.81654592, 0.67656899],
    [ 0.40634556, 0.81991799, 0.67686215],
    [ 0.41730243, 0.82318339, 0.67735255],
    [ 0.4285828 , 0.82635051, 0.6780564 ],
    [ 0.44012728, 0.82942353, 0.67900049],
    [ 0.45189421, 0.83240398, 0.68021733],
    [ 0.46378379, 0.83530763, 0.6817062 ],
    [ 0.47573199, 0.83814472, 0.68347352],
    [ 0.48769865, 0.84092197, 0.68552698],
    [ 0.49962354, 0.84365379, 0.68783929],
    [ 0.5114027 , 0.8463718 , 0.69029789],
    [ 0.52301693, 0.84908401, 0.69288545],
    [ 0.53447549, 0.85179048, 0.69561066],
    [ 0.54578602, 0.8544913 , 0.69848331],
    [ 0.55695565, 0.85718723, 0.70150427],
    [ 0.56798832, 0.85987893, 0.70468261],
    [ 0.57888639, 0.86256715, 0.70802931],
    [ 0.5896541 , 0.8652532 , 0.71154204],
    [ 0.60028928, 0.86793835, 0.71523675],
    [ 0.61079441, 0.87062438, 0.71910895],
    [ 0.62116633, 0.87331311, 0.72317003],
    [ 0.63140509, 0.87600675, 0.72741689],
    [ 0.64150735, 0.87870746, 0.73185717],
    [ 0.65147219, 0.8814179 , 0.73648495],
    [ 0.66129632, 0.8841403 , 0.74130658],
    [ 0.67097934, 0.88687758, 0.74631123],
    [ 0.68051833, 0.88963189, 0.75150483],
    [ 0.68991419, 0.89240612, 0.75687187],
    [ 0.69916533, 0.89520211, 0.76241714],
    [ 0.70827373, 0.89802257, 0.76812286],
    [ 0.71723995, 0.90086891, 0.77399039],
    [ 0.72606665, 0.90374337, 0.7800041 ],
    [ 0.73475675, 0.90664718, 0.78615802],
    [ 0.74331358, 0.90958151, 0.79244474],
    [ 0.75174143, 0.91254787, 0.79884925],
    [ 0.76004473, 0.91554656, 0.80536823],
    [ 0.76827704, 0.91856549, 0.81196513],
    [ 0.77647029, 0.921603  , 0.81855729],
    [ 0.78462009, 0.92466151, 0.82514119],
    [ 0.79273542, 0.92773848, 0.83172131],
    [ 0.8008109 , 0.93083672, 0.83829355],
    [ 0.80885107, 0.93395528, 0.84485982],
    [ 0.81685878, 0.9370938 , 0.85142101],
    [ 0.82483206, 0.94025378, 0.8579751 ],
    [ 0.83277661, 0.94343371, 0.86452477],
    [ 0.84069127, 0.94663473, 0.87106853],
    [ 0.84857662, 0.9498573 , 0.8776059 ],
    [ 0.8564431 , 0.95309792, 0.88414253],
    [ 0.86429066, 0.95635719, 0.89067759],
    [ 0.87218969, 0.95960708, 0.89725384]
]

export const vlag = [
    [ 0.13850039, 0.41331206, 0.74052025],
    [ 0.15077609, 0.41762684, 0.73970427],
    [ 0.16235219, 0.4219191 , 0.7389667 ],
    [ 0.1733322 , 0.42619024, 0.73832537],
    [ 0.18382538, 0.43044226, 0.73776764],
    [ 0.19394034, 0.4346772 , 0.73725867],
    [ 0.20367115, 0.43889576, 0.73685314],
    [ 0.21313625, 0.44310003, 0.73648045],
    [ 0.22231173, 0.44729079, 0.73619681],
    [ 0.23125148, 0.45146945, 0.73597803],
    [ 0.23998101, 0.45563715, 0.7358223 ],
    [ 0.24853358, 0.45979489, 0.73571524],
    [ 0.25691416, 0.4639437 , 0.73566943],
    [ 0.26513894, 0.46808455, 0.73568319],
    [ 0.27322194, 0.47221835, 0.73575497],
    [ 0.28117543, 0.47634598, 0.73588332],
    [ 0.28901021, 0.48046826, 0.73606686],
    [ 0.2967358 , 0.48458597, 0.73630433],
    [ 0.30436071, 0.48869986, 0.73659451],
    [ 0.3118955 , 0.49281055, 0.73693255],
    [ 0.31935389, 0.49691847, 0.73730851],
    [ 0.32672701, 0.5010247 , 0.73774013],
    [ 0.33402607, 0.50512971, 0.73821941],
    [ 0.34125337, 0.50923419, 0.73874905],
    [ 0.34840921, 0.51333892, 0.73933402],
    [ 0.35551826, 0.51744353, 0.73994642],
    [ 0.3625676 , 0.52154929, 0.74060763],
    [ 0.36956356, 0.52565656, 0.74131327],
    [ 0.37649902, 0.52976642, 0.74207698],
    [ 0.38340273, 0.53387791, 0.74286286],
    [ 0.39025859, 0.53799253, 0.7436962 ],
    [ 0.39706821, 0.54211081, 0.744578  ],
    [ 0.40384046, 0.54623277, 0.74549872],
    [ 0.41058241, 0.55035849, 0.74645094],
    [ 0.41728385, 0.55448919, 0.74745174],
    [ 0.42395178, 0.55862494, 0.74849357],
    [ 0.4305964 , 0.56276546, 0.74956387],
    [ 0.4372044 , 0.56691228, 0.75068412],
    [ 0.4437909 , 0.57106468, 0.75183427],
    [ 0.45035117, 0.5752235 , 0.75302312],
    [ 0.45687824, 0.57938983, 0.75426297],
    [ 0.46339713, 0.58356191, 0.75551816],
    [ 0.46988778, 0.58774195, 0.75682037],
    [ 0.47635605, 0.59192986, 0.75816245],
    [ 0.48281101, 0.5961252 , 0.75953212],
    [ 0.4892374 , 0.60032986, 0.76095418],
    [ 0.49566225, 0.60454154, 0.76238852],
    [ 0.50206137, 0.60876307, 0.76387371],
    [ 0.50845128, 0.61299312, 0.76538551],
    [ 0.5148258 , 0.61723272, 0.76693475],
    [ 0.52118385, 0.62148236, 0.76852436],
    [ 0.52753571, 0.62574126, 0.77013939],
    [ 0.53386831, 0.63001125, 0.77180152],
    [ 0.54020159, 0.63429038, 0.7734803 ],
    [ 0.54651272, 0.63858165, 0.77521306],
    [ 0.55282975, 0.64288207, 0.77695608],
    [ 0.55912585, 0.64719519, 0.77875327],
    [ 0.56542599, 0.65151828, 0.78056551],
    [ 0.57170924, 0.65585426, 0.78242747],
    [ 0.57799572, 0.6602009 , 0.78430751],
    [ 0.58426817, 0.66456073, 0.78623458],
    [ 0.590544  , 0.66893178, 0.78818117],
    [ 0.59680758, 0.67331643, 0.79017369],
    [ 0.60307553, 0.67771273, 0.79218572],
    [ 0.60934065, 0.68212194, 0.79422987],
    [ 0.61559495, 0.68654548, 0.7963202 ],
    [ 0.62185554, 0.69098125, 0.79842918],
    [ 0.62810662, 0.69543176, 0.80058381],
    [ 0.63436425, 0.69989499, 0.80275812],
    [ 0.64061445, 0.70437326, 0.80497621],
    [ 0.6468706 , 0.70886488, 0.80721641],
    [ 0.65312213, 0.7133717 , 0.80949719],
    [ 0.65937818, 0.71789261, 0.81180392],
    [ 0.66563334, 0.72242871, 0.81414642],
    [ 0.67189155, 0.72697967, 0.81651872],
    [ 0.67815314, 0.73154569, 0.81892097],
    [ 0.68441395, 0.73612771, 0.82136094],
    [ 0.69068321, 0.74072452, 0.82382353],
    [ 0.69694776, 0.7453385 , 0.82633199],
    [ 0.70322431, 0.74996721, 0.8288583 ],
    [ 0.70949595, 0.75461368, 0.83143221],
    [ 0.7157774 , 0.75927574, 0.83402904],
    [ 0.72206299, 0.76395461, 0.83665922],
    [ 0.72835227, 0.76865061, 0.8393242 ],
    [ 0.73465238, 0.7733628 , 0.84201224],
    [ 0.74094862, 0.77809393, 0.84474951],
    [ 0.74725683, 0.78284158, 0.84750915],
    [ 0.75357103, 0.78760701, 0.85030217],
    [ 0.75988961, 0.79239077, 0.85313207],
    [ 0.76621987, 0.79719185, 0.85598668],
    [ 0.77255045, 0.8020125 , 0.85888658],
    [ 0.77889241, 0.80685102, 0.86181298],
    [ 0.78524572, 0.81170768, 0.86476656],
    [ 0.79159841, 0.81658489, 0.86776906],
    [ 0.79796459, 0.82148036, 0.8707962 ],
    [ 0.80434168, 0.82639479, 0.87385315],
    [ 0.8107221 , 0.83132983, 0.87695392],
    [ 0.81711301, 0.8362844 , 0.88008641],
    [ 0.82351479, 0.84125863, 0.88325045],
    [ 0.82992772, 0.84625263, 0.88644594],
    [ 0.83634359, 0.85126806, 0.8896878 ],
    [ 0.84277295, 0.85630293, 0.89295721],
    [ 0.84921192, 0.86135782, 0.89626076],
    [ 0.85566206, 0.866432  , 0.89959467],
    [ 0.86211514, 0.87152627, 0.90297183],
    [ 0.86857483, 0.87663856, 0.90638248],
    [ 0.87504231, 0.88176648, 0.90981938],
    [ 0.88151194, 0.88690782, 0.91328493],
    [ 0.88797938, 0.89205857, 0.91677544],
    [ 0.89443865, 0.89721298, 0.9202854 ],
    [ 0.90088204, 0.90236294, 0.92380601],
    [ 0.90729768, 0.90749778, 0.92732797],
    [ 0.91367037, 0.91260329, 0.93083814],
    [ 0.91998105, 0.91766106, 0.93431861],
    [ 0.92620596, 0.92264789, 0.93774647],
    [ 0.93231683, 0.9275351 , 0.94109192],
    [ 0.93827772, 0.9322888 , 0.94432312],
    [ 0.94404755, 0.93686925, 0.94740137],
    [ 0.94958284, 0.94123072, 0.95027696],
    [ 0.95482682, 0.9453245 , 0.95291103],
    [ 0.9597248 , 0.94909728, 0.95525103],
    [ 0.96422552, 0.95249273, 0.95723271],
    [ 0.96826161, 0.95545812, 0.95882188],
    [ 0.97178458, 0.95793984, 0.95995705],
    [ 0.97474105, 0.95989142, 0.96059997],
    [ 0.97708604, 0.96127366, 0.96071853],
    [ 0.97877855, 0.96205832, 0.96030095],
    [ 0.97978484, 0.96222949, 0.95935496],
    [ 0.9805997 , 0.96155216, 0.95813083],
    [ 0.98152619, 0.95993719, 0.95639322],
    [ 0.9819726 , 0.95766608, 0.95399269],
    [ 0.98191855, 0.9547873 , 0.95098107],
    [ 0.98138514, 0.95134771, 0.94740644],
    [ 0.98040845, 0.94739906, 0.94332125],
    [ 0.97902107, 0.94300131, 0.93878672],
    [ 0.97729348, 0.93820409, 0.93385135],
    [ 0.9752533 , 0.933073  , 0.92858252],
    [ 0.97297834, 0.92765261, 0.92302309],
    [ 0.97049104, 0.92200317, 0.91723505],
    [ 0.96784372, 0.91616744, 0.91126063],
    [ 0.96507281, 0.91018664, 0.90514124],
    [ 0.96222034, 0.90409203, 0.89890756],
    [ 0.9593079 , 0.89791478, 0.89259122],
    [ 0.95635626, 0.89167908, 0.88621654],
    [ 0.95338303, 0.88540373, 0.87980238],
    [ 0.95040174, 0.87910333, 0.87336339],
    [ 0.94742246, 0.87278899, 0.86691076],
    [ 0.94445249, 0.86646893, 0.86045277],
    [ 0.94150476, 0.86014606, 0.85399191],
    [ 0.93857394, 0.85382798, 0.84753642],
    [ 0.93566206, 0.84751766, 0.84108935],
    [ 0.93277194, 0.8412164 , 0.83465197],
    [ 0.92990106, 0.83492672, 0.82822708],
    [ 0.92704736, 0.82865028, 0.82181656],
    [ 0.92422703, 0.82238092, 0.81541333],
    [ 0.92142581, 0.81612448, 0.80902415],
    [ 0.91864501, 0.80988032, 0.80264838],
    [ 0.91587578, 0.80365187, 0.79629001],
    [ 0.9131367 , 0.79743115, 0.78994   ],
    [ 0.91041602, 0.79122265, 0.78360361],
    [ 0.90771071, 0.78502727, 0.77728196],
    [ 0.90501581, 0.77884674, 0.7709771 ],
    [ 0.90235365, 0.77267117, 0.76467793],
    [ 0.8997019 , 0.76650962, 0.75839484],
    [ 0.89705346, 0.76036481, 0.752131  ],
    [ 0.89444021, 0.75422253, 0.74587047],
    [ 0.89183355, 0.74809474, 0.73962689],
    [ 0.88923216, 0.74198168, 0.73340061],
    [ 0.88665892, 0.73587283, 0.72717995],
    [ 0.88408839, 0.72977904, 0.72097718],
    [ 0.88153537, 0.72369332, 0.71478461],
    [ 0.87899389, 0.7176179 , 0.70860487],
    [ 0.87645157, 0.71155805, 0.7024439 ],
    [ 0.8739399 , 0.70549893, 0.6962854 ],
    [ 0.87142626, 0.6994551 , 0.69014561],
    [ 0.8689268 , 0.69341868, 0.68401597],
    [ 0.86643562, 0.687392  , 0.67789917],
    [ 0.86394434, 0.68137863, 0.67179927],
    [ 0.86147586, 0.67536728, 0.665704  ],
    [ 0.85899928, 0.66937226, 0.6596292 ],
    [ 0.85654668, 0.66337773, 0.6535577 ],
    [ 0.85408818, 0.65739772, 0.64750494],
    [ 0.85164413, 0.65142189, 0.64145983],
    [ 0.84920091, 0.6454565 , 0.63542932],
    [ 0.84676427, 0.63949827, 0.62941   ],
    [ 0.84433231, 0.63354773, 0.62340261],
    [ 0.84190106, 0.62760645, 0.61740899],
    [ 0.83947935, 0.62166951, 0.61142404],
    [ 0.8370538 , 0.61574332, 0.60545478],
    [ 0.83463975, 0.60981951, 0.59949247],
    [ 0.83221877, 0.60390724, 0.593547  ],
    [ 0.82980985, 0.59799607, 0.58760751],
    [ 0.82740268, 0.59209095, 0.58167944],
    [ 0.82498638, 0.5861973 , 0.57576866],
    [ 0.82258181, 0.5803034 , 0.56986307],
    [ 0.82016611, 0.57442123, 0.56397539],
    [ 0.81776305, 0.56853725, 0.55809173],
    [ 0.81534551, 0.56266602, 0.55222741],
    [ 0.81294293, 0.55679056, 0.5463651 ],
    [ 0.81052113, 0.55092973, 0.54052443],
    [ 0.80811509, 0.54506305, 0.53468464],
    [ 0.80568952, 0.53921036, 0.52886622],
    [ 0.80327506, 0.53335335, 0.52305077],
    [ 0.80084727, 0.52750583, 0.51725256],
    [ 0.79842217, 0.5216578 , 0.51146173],
    [ 0.79599382, 0.51581223, 0.50568155],
    [ 0.79355781, 0.50997127, 0.49991444],
    [ 0.79112596, 0.50412707, 0.49415289],
    [ 0.78867442, 0.49829386, 0.48841129],
    [ 0.7862306 , 0.49245398, 0.48267247],
    [ 0.7837687 , 0.48662309, 0.47695216],
    [ 0.78130809, 0.4807883 , 0.47123805],
    [ 0.77884467, 0.47495151, 0.46553236],
    [ 0.77636283, 0.46912235, 0.45984473],
    [ 0.77388383, 0.46328617, 0.45416141],
    [ 0.77138912, 0.45745466, 0.44849398],
    [ 0.76888874, 0.45162042, 0.44283573],
    [ 0.76638802, 0.44577901, 0.43718292],
    [ 0.76386116, 0.43994762, 0.43155211],
    [ 0.76133542, 0.43410655, 0.42592523],
    [ 0.75880631, 0.42825801, 0.42030488],
    [ 0.75624913, 0.42241905, 0.41470727],
    [ 0.7536919 , 0.41656866, 0.40911347],
    [ 0.75112748, 0.41071104, 0.40352792],
    [ 0.74854331, 0.40485474, 0.3979589 ],
    [ 0.74594723, 0.39899309, 0.39240088],
    [ 0.74334332, 0.39312199, 0.38685075],
    [ 0.74073277, 0.38723941, 0.3813074 ],
    [ 0.73809409, 0.38136133, 0.37578553],
    [ 0.73544692, 0.37547129, 0.37027123],
    [ 0.73278943, 0.36956954, 0.36476549],
    [ 0.73011829, 0.36365761, 0.35927038],
    [ 0.72743485, 0.35773314, 0.35378465],
    [ 0.72472722, 0.35180504, 0.34831662],
    [ 0.72200473, 0.34586421, 0.34285937],
    [ 0.71927052, 0.33990649, 0.33741033],
    [ 0.71652049, 0.33393396, 0.33197219],
    [ 0.71375362, 0.32794602, 0.32654545],
    [ 0.71096951, 0.32194148, 0.32113016],
    [ 0.70816772, 0.31591904, 0.31572637],
    [ 0.70534784, 0.30987734, 0.31033414],
    [ 0.70250944, 0.30381489, 0.30495353],
    [ 0.69965211, 0.2977301 , 0.2995846 ],
    [ 0.6967754 , 0.29162126, 0.29422741],
    [ 0.69388446, 0.28548074, 0.28887769],
    [ 0.69097561, 0.2793096 , 0.28353795],
    [ 0.68803513, 0.27311993, 0.27821876],
    [ 0.6850794 , 0.26689144, 0.27290694],
    [ 0.682108  , 0.26062114, 0.26760246],
    [ 0.67911013, 0.2543177 , 0.26231367],
    [ 0.67609393, 0.24796818, 0.25703372],
    [ 0.67305921, 0.24156846, 0.25176238],
    [ 0.67000176, 0.23511902, 0.24650278],
    [ 0.66693423, 0.22859879, 0.24124404],
    [ 0.6638441 , 0.22201742, 0.2359961 ],
    [ 0.66080672, 0.21526712, 0.23069468]
]

export const icefire = [
    [ 0.73936227, 0.90443867, 0.85757238],
    [ 0.72888063, 0.89639109, 0.85488394],
    [ 0.71834255, 0.88842162, 0.8521605 ],
    [ 0.70773866, 0.88052939, 0.849422  ],
    [ 0.69706215, 0.87271313, 0.84668315],
    [ 0.68629021, 0.86497329, 0.84398721],
    [ 0.67543654, 0.85730617, 0.84130969],
    [ 0.66448539, 0.84971123, 0.83868005],
    [ 0.65342679, 0.84218728, 0.83611512],
    [ 0.64231804, 0.83471867, 0.83358584],
    [ 0.63117745, 0.827294  , 0.83113431],
    [ 0.62000484, 0.81991069, 0.82876741],
    [ 0.60879435, 0.81256797, 0.82648905],
    [ 0.59754118, 0.80526458, 0.82430414],
    [ 0.58624247, 0.79799884, 0.82221573],
    [ 0.57489525, 0.7907688 , 0.82022901],
    [ 0.56349779, 0.78357215, 0.81834861],
    [ 0.55204294, 0.77640827, 0.81657563],
    [ 0.54052516, 0.76927562, 0.81491462],
    [ 0.52894085, 0.76217215, 0.81336913],
    [ 0.51728854, 0.75509528, 0.81194156],
    [ 0.50555676, 0.74804469, 0.81063503],
    [ 0.49373871, 0.7410187 , 0.80945242],
    [ 0.48183174, 0.73401449, 0.80839675],
    [ 0.46982587, 0.72703075, 0.80747097],
    [ 0.45770893, 0.72006648, 0.80667756],
    [ 0.44547249, 0.71311941, 0.80601991],
    [ 0.43318643, 0.70617126, 0.80549278],
    [ 0.42110294, 0.69916972, 0.80506683],
    [ 0.40925101, 0.69211059, 0.80473246],
    [ 0.3976693 , 0.68498786, 0.80448272],
    [ 0.38632002, 0.67781125, 0.80431024],
    [ 0.37523981, 0.67057537, 0.80420832],
    [ 0.36442578, 0.66328229, 0.80417474],
    [ 0.35385939, 0.65593699, 0.80420591],
    [ 0.34358916, 0.64853177, 0.8043    ],
    [ 0.33355526, 0.64107876, 0.80445484],
    [ 0.32383062, 0.63356578, 0.80467091],
    [ 0.31434372, 0.62600624, 0.8049475 ],
    [ 0.30516161, 0.618389  , 0.80528692],
    [ 0.29623491, 0.61072284, 0.80569021],
    [ 0.28759072, 0.60300319, 0.80616055],
    [ 0.27923924, 0.59522877, 0.80669803],
    [ 0.27114651, 0.5874047 , 0.80730545],
    [ 0.26337153, 0.57952055, 0.80799113],
    [ 0.25588696, 0.57157984, 0.80875922],
    [ 0.248686  , 0.56358255, 0.80961366],
    [ 0.24180668, 0.55552289, 0.81055123],
    [ 0.23526251, 0.54739477, 0.8115939 ],
    [ 0.22921445, 0.53918506, 0.81267292],
    [ 0.22397687, 0.53086094, 0.8137141 ],
    [ 0.21977058, 0.52241482, 0.81457651],
    [ 0.21658989, 0.51384321, 0.81528511],
    [ 0.21452772, 0.50514155, 0.81577278],
    [ 0.21372783, 0.49630865, 0.81589566],
    [ 0.21409503, 0.48734861, 0.81566163],
    [ 0.2157176 , 0.47827123, 0.81487615],
    [ 0.21842857, 0.46909168, 0.81351614],
    [ 0.22211705, 0.45983212, 0.81146983],
    [ 0.22665681, 0.45052233, 0.80860217],
    [ 0.23176013, 0.44119137, 0.80494325],
    [ 0.23727775, 0.43187704, 0.80038017],
    [ 0.24298285, 0.42261123, 0.79493267],
    [ 0.24865068, 0.41341842, 0.78869164],
    [ 0.25423116, 0.40433127, 0.78155831],
    [ 0.25950239, 0.39535521, 0.77376848],
    [ 0.2644736 , 0.38651212, 0.76524809],
    [ 0.26901584, 0.37779582, 0.75621942],
    [ 0.27318141, 0.36922056, 0.746605  ],
    [ 0.27690355, 0.3607736 , 0.73659374],
    [ 0.28023585, 0.35244234, 0.72622103],
    [ 0.28306009, 0.34438449, 0.71500731],
    [ 0.28535896, 0.33660243, 0.70303975],
    [ 0.28708711, 0.32912157, 0.69034504],
    [ 0.28816354, 0.32200604, 0.67684067],
    [ 0.28862749, 0.31519824, 0.66278813],
    [ 0.28847904, 0.30869064, 0.6482815 ],
    [ 0.28770912, 0.30250126, 0.63331265],
    [ 0.28640325, 0.29655509, 0.61811374],
    [ 0.28458943, 0.29082155, 0.60280913],
    [ 0.28233561, 0.28527482, 0.58742866],
    [ 0.27967038, 0.2798938 , 0.57204225],
    [ 0.27665361, 0.27465357, 0.55667809],
    [ 0.27332564, 0.2695165 , 0.54145387],
    [ 0.26973851, 0.26447054, 0.52634916],
    [ 0.2659204 , 0.25949691, 0.511417  ],
    [ 0.26190145, 0.25458123, 0.49668768],
    [ 0.2577151 , 0.24971691, 0.48214874],
    [ 0.25337618, 0.24490494, 0.46778758],
    [ 0.24890842, 0.24013332, 0.45363816],
    [ 0.24433654, 0.23539226, 0.4397245 ],
    [ 0.23967922, 0.23067729, 0.4260591 ],
    [ 0.23495608, 0.22598894, 0.41262952],
    [ 0.23018113, 0.22132414, 0.39945577],
    [ 0.22534609, 0.21670847, 0.38645794],
    [ 0.22048761, 0.21211723, 0.37372555],
    [ 0.2156198 , 0.20755389, 0.36125301],
    [ 0.21074637, 0.20302717, 0.34903192],
    [ 0.20586893, 0.19855368, 0.33701661],
    [ 0.20101757, 0.19411573, 0.32529173],
    [ 0.19619947, 0.18972425, 0.31383846],
    [ 0.19140726, 0.18540157, 0.30260777],
    [ 0.1866769 , 0.1811332 , 0.29166583],
    [ 0.18201285, 0.17694992, 0.28088776],
    [ 0.17745228, 0.17282141, 0.27044211],
    [ 0.17300684, 0.16876921, 0.26024893],
    [ 0.16868273, 0.16479861, 0.25034479],
    [ 0.16448691, 0.16091728, 0.24075373],
    [ 0.16043195, 0.15714351, 0.23141745],
    [ 0.15652427, 0.15348248, 0.22238175],
    [ 0.15277065, 0.14994111, 0.21368395],
    [ 0.14918274, 0.14653431, 0.20529486],
    [ 0.14577095, 0.14327403, 0.19720829],
    [ 0.14254381, 0.14016944, 0.18944326],
    [ 0.13951035, 0.13723063, 0.18201072],
    [ 0.13667798, 0.13446606, 0.17493774],
    [ 0.13405762, 0.13188822, 0.16820842],
    [ 0.13165767, 0.12950667, 0.16183275],
    [ 0.12948748, 0.12733187, 0.15580631],
    [ 0.12755435, 0.1253723 , 0.15014098],
    [ 0.12586516, 0.12363617, 0.1448459 ],
    [ 0.12442647, 0.12213143, 0.13992571],
    [ 0.12324241, 0.12086419, 0.13539995],
    [ 0.12232067, 0.11984278, 0.13124644],
    [ 0.12166209, 0.11907077, 0.12749671],
    [ 0.12126982, 0.11855309, 0.12415079],
    [ 0.12114244, 0.11829179, 0.1212385 ],
    [ 0.12127766, 0.11828837, 0.11878534],
    [ 0.12284806, 0.1179729 , 0.11772022],
    [ 0.12619498, 0.11721796, 0.11770203],
    [ 0.129968  , 0.11663788, 0.11792377],
    [ 0.13410011, 0.11625146, 0.11839138],
    [ 0.13855459, 0.11606618, 0.11910584],
    [ 0.14333775, 0.11607038, 0.1200606 ],
    [ 0.148417  , 0.11626929, 0.12125453],
    [ 0.15377389, 0.11666192, 0.12268364],
    [ 0.15941427, 0.11723486, 0.12433911],
    [ 0.16533376, 0.11797856, 0.12621303],
    [ 0.17152547, 0.11888403, 0.12829735],
    [ 0.17797765, 0.11994436, 0.13058435],
    [ 0.18468769, 0.12114722, 0.13306426],
    [ 0.19165663, 0.12247737, 0.13572616],
    [ 0.19884415, 0.12394381, 0.1385669 ],
    [ 0.20627181, 0.12551883, 0.14157124],
    [ 0.21394877, 0.12718055, 0.14472604],
    [ 0.22184572, 0.12893119, 0.14802579],
    [ 0.22994394, 0.13076731, 0.15146314],
    [ 0.23823937, 0.13267611, 0.15502793],
    [ 0.24676041, 0.13462172, 0.15870321],
    [ 0.25546457, 0.13661751, 0.16248722],
    [ 0.26433628, 0.13865956, 0.16637301],
    [ 0.27341345, 0.14070412, 0.17034221],
    [ 0.28264773, 0.14277192, 0.1743957 ],
    [ 0.29202272, 0.14486161, 0.17852793],
    [ 0.30159648, 0.14691224, 0.1827169 ],
    [ 0.31129002, 0.14897583, 0.18695213],
    [ 0.32111555, 0.15103351, 0.19119629],
    [ 0.33107961, 0.1530674 , 0.19543758],
    [ 0.34119892, 0.15504762, 0.1996803 ],
    [ 0.35142388, 0.15701131, 0.20389086],
    [ 0.36178937, 0.1589124 , 0.20807639],
    [ 0.37229381, 0.16073993, 0.21223189],
    [ 0.38288348, 0.16254006, 0.2163249 ],
    [ 0.39359592, 0.16426336, 0.22036577],
    [ 0.40444332, 0.16588767, 0.22434027],
    [ 0.41537995, 0.16745325, 0.2282297 ],
    [ 0.42640867, 0.16894939, 0.23202755],
    [ 0.43754706, 0.17034847, 0.23572899],
    [ 0.44878564, 0.1716535 , 0.23932344],
    [ 0.4601126 , 0.17287365, 0.24278607],
    [ 0.47151732, 0.17401641, 0.24610337],
    [ 0.48300689, 0.17506676, 0.2492737 ],
    [ 0.49458302, 0.17601892, 0.25227688],
    [ 0.50623876, 0.17687777, 0.255096  ],
    [ 0.5179623 , 0.17765528, 0.2577162 ],
    [ 0.52975234, 0.17835232, 0.2601134 ],
    [ 0.54159776, 0.17898292, 0.26226847],
    [ 0.55348804, 0.17956232, 0.26416003],
    [ 0.56541729, 0.18010175, 0.26575971],
    [ 0.57736669, 0.180631  , 0.26704888],
    [ 0.58932081, 0.18117827, 0.26800409],
    [ 0.60127582, 0.18175888, 0.26858488],
    [ 0.61319563, 0.1824336 , 0.2687872 ],
    [ 0.62506376, 0.18324015, 0.26858301],
    [ 0.63681202, 0.18430173, 0.26795276],
    [ 0.64842603, 0.18565472, 0.26689463],
    [ 0.65988195, 0.18734638, 0.26543435],
    [ 0.67111966, 0.18948885, 0.26357955],
    [ 0.68209194, 0.19216636, 0.26137175],
    [ 0.69281185, 0.19535326, 0.25887063],
    [ 0.70335022, 0.19891271, 0.25617971],
    [ 0.71375229, 0.20276438, 0.25331365],
    [ 0.72401436, 0.20691287, 0.25027366],
    [ 0.73407638, 0.21145051, 0.24710661],
    [ 0.74396983, 0.21631913, 0.24380715],
    [ 0.75361506, 0.22163653, 0.24043996],
    [ 0.7630579 , 0.22731637, 0.23700095],
    [ 0.77222228, 0.23346231, 0.23356628],
    [ 0.78115441, 0.23998404, 0.23013825],
    [ 0.78979746, 0.24694858, 0.22678822],
    [ 0.79819286, 0.25427223, 0.22352658],
    [ 0.80630444, 0.26198807, 0.22040877],
    [ 0.81417437, 0.27001406, 0.21744645],
    [ 0.82177364, 0.27837336, 0.21468316],
    [ 0.82915955, 0.28696963, 0.21210766],
    [ 0.83628628, 0.2958499 , 0.20977813],
    [ 0.84322168, 0.30491136, 0.20766435],
    [ 0.84995458, 0.31415945, 0.2057863 ],
    [ 0.85648867, 0.32358058, 0.20415327],
    [ 0.86286243, 0.33312058, 0.20274969],
    [ 0.86908321, 0.34276705, 0.20157271],
    [ 0.87512876, 0.3525416 , 0.20064949],
    [ 0.88100349, 0.36243385, 0.19999078],
    [ 0.8866469 , 0.37249496, 0.1997976 ],
    [ 0.89203964, 0.38273475, 0.20013431],
    [ 0.89713496, 0.39318156, 0.20121514],
    [ 0.90195099, 0.40380687, 0.20301555],
    [ 0.90648379, 0.41460191, 0.20558847],
    [ 0.9106967 , 0.42557857, 0.20918529],
    [ 0.91463791, 0.43668557, 0.21367954],
    [ 0.91830723, 0.44790913, 0.21916352],
    [ 0.92171507, 0.45922856, 0.22568002],
    [ 0.92491786, 0.4705936 , 0.23308207],
    [ 0.92790792, 0.48200153, 0.24145932],
    [ 0.93073701, 0.49341219, 0.25065486],
    [ 0.93343918, 0.5048017 , 0.26056148],
    [ 0.93602064, 0.51616486, 0.27118485],
    [ 0.93850535, 0.52748892, 0.28242464],
    [ 0.94092933, 0.53875462, 0.29416042],
    [ 0.94330011, 0.5499628 , 0.30634189],
    [ 0.94563159, 0.56110987, 0.31891624],
    [ 0.94792955, 0.57219822, 0.33184256],
    [ 0.95020929, 0.5832232 , 0.34508419],
    [ 0.95247324, 0.59419035, 0.35859866],
    [ 0.95471709, 0.60510869, 0.37236035],
    [ 0.95698411, 0.61595766, 0.38629631],
    [ 0.95923863, 0.62676473, 0.40043317],
    [ 0.9615041 , 0.6375203 , 0.41474106],
    [ 0.96371553, 0.64826619, 0.42928335],
    [ 0.96591497, 0.65899621, 0.44380444],
    [ 0.96809871, 0.66971662, 0.45830232],
    [ 0.9702495 , 0.6804394 , 0.47280492],
    [ 0.9723881 , 0.69115622, 0.48729272],
    [ 0.97450723, 0.70187358, 0.50178034],
    [ 0.9766108 , 0.712592  , 0.51626837],
    [ 0.97871716, 0.72330511, 0.53074053],
    [ 0.98082222, 0.73401769, 0.54520694],
    [ 0.9829001 , 0.74474445, 0.5597019 ],
    [ 0.98497466, 0.75547635, 0.57420239],
    [ 0.98705581, 0.76621129, 0.58870185],
    [ 0.98913325, 0.77695637, 0.60321626],
    [ 0.99119918, 0.78771716, 0.61775821],
    [ 0.9932672 , 0.79848979, 0.63231691],
    [ 0.99535958, 0.80926704, 0.64687278],
    [ 0.99740544, 0.82008078, 0.66150571],
    [ 0.9992197 , 0.83100723, 0.6764127 ]
]

export const flare = [
    [0.92907237, 0.68878959, 0.50411509],
    [0.92891402, 0.68494686, 0.50173994],
    [0.92864754, 0.68116207, 0.4993754],
    [0.92836112, 0.67738527, 0.49701572],
    [0.9280599, 0.67361354, 0.49466044],
    [0.92775569, 0.66983999, 0.49230866],
    [0.9274375, 0.66607098, 0.48996097],
    [0.927111, 0.66230315, 0.48761688],
    [0.92677996, 0.6585342, 0.485276],
    [0.92644317, 0.65476476, 0.48293832],
    [0.92609759, 0.65099658, 0.48060392],
    [0.925747, 0.64722729, 0.47827244],
    [0.92539502, 0.64345456, 0.47594352],
    [0.92503106, 0.6396848, 0.47361782],
    [0.92466877, 0.6359095, 0.47129427],
    [0.92429828, 0.63213463, 0.46897349],
    [0.92392172, 0.62835879, 0.46665526],
    [0.92354597, 0.62457749, 0.46433898],
    [0.9231622, 0.6207962, 0.46202524],
    [0.92277222, 0.61701365, 0.45971384],
    [0.92237978, 0.61322733, 0.45740444],
    [0.92198615, 0.60943622, 0.45509686],
    [0.92158735, 0.60564276, 0.45279137],
    [0.92118373, 0.60184659, 0.45048789],
    [0.92077582, 0.59804722, 0.44818634],
    [0.92036413, 0.59424414, 0.44588663],
    [0.91994924, 0.5904368, 0.44358868],
    [0.91952943, 0.58662619, 0.4412926],
    [0.91910675, 0.58281075, 0.43899817],
    [0.91868096, 0.57899046, 0.4367054],
    [0.91825103, 0.57516584, 0.43441436],
    [0.91781857, 0.57133556, 0.43212486],
    [0.9173814, 0.56750099, 0.4298371],
    [0.91694139, 0.56366058, 0.42755089],
    [0.91649756, 0.55981483, 0.42526631],
    [0.91604942, 0.55596387, 0.42298339],
    [0.9155979, 0.55210684, 0.42070204],
    [0.9151409, 0.54824485, 0.4184247],
    [0.91466138, 0.54438817, 0.41617858],
    [0.91416896, 0.54052962, 0.41396347],
    [0.91366559, 0.53666778, 0.41177769],
    [0.91315173, 0.53280208, 0.40962196],
    [0.91262605, 0.52893336, 0.40749715],
    [0.91208866, 0.52506133, 0.40540404],
    [0.91153952, 0.52118582, 0.40334346],
    [0.91097732, 0.51730767, 0.4013163],
    [0.910403, 0.51342591, 0.39932342],
    [0.90981494, 0.50954168, 0.39736571],
    [0.90921368, 0.5056543, 0.39544411],
    [0.90859797, 0.50176463, 0.39355952],
    [0.90796841, 0.49787195, 0.39171297],
    [0.90732341, 0.4939774, 0.38990532],
    [0.90666382, 0.49008006, 0.38813773],
    [0.90598815, 0.486181, 0.38641107],
    [0.90529624, 0.48228017, 0.38472641],
    [0.90458808, 0.47837738, 0.38308489],
    [0.90386248, 0.47447348, 0.38148746],
    [0.90311921, 0.4705685, 0.37993524],
    [0.90235809, 0.46666239, 0.37842943],
    [0.90157824, 0.46275577, 0.37697105],
    [0.90077904, 0.45884905, 0.37556121],
    [0.89995995, 0.45494253, 0.37420106],
    [0.89912041, 0.4510366, 0.37289175],
    [0.8982602, 0.44713126, 0.37163458],
    [0.89737819, 0.44322747, 0.37043052],
    [0.89647387, 0.43932557, 0.36928078],
    [0.89554477, 0.43542759, 0.36818855],
    [0.89458871, 0.4315354, 0.36715654],
    [0.89360794, 0.42764714, 0.36618273],
    [0.89260152, 0.42376366, 0.36526813],
    [0.8915687, 0.41988565, 0.36441384],
    [0.89050882, 0.41601371, 0.36362102],
    [0.8894159, 0.41215334, 0.36289639],
    [0.888292, 0.40830288, 0.36223756],
    [0.88713784, 0.40446193, 0.36164328],
    [0.88595253, 0.40063149, 0.36111438],
    [0.88473115, 0.39681635, 0.3606566],
    [0.88347246, 0.39301805, 0.36027074],
    [0.88217931, 0.38923439, 0.35995244],
    [0.880851, 0.38546632, 0.35970244],
    [0.87947728, 0.38172422, 0.35953127],
    [0.87806542, 0.37800172, 0.35942941],
    [0.87661509, 0.37429964, 0.35939659],
    [0.87511668, 0.37062819, 0.35944178],
    [0.87357554, 0.36698279, 0.35955811],
    [0.87199254, 0.3633634, 0.35974223],
    [0.87035691, 0.35978174, 0.36000516],
    [0.86867647, 0.35623087, 0.36033559],
    [0.86694949, 0.35271349, 0.36073358],
    [0.86516775, 0.34923921, 0.36120624],
    [0.86333996, 0.34580008, 0.36174113],
    [0.86145909, 0.3424046, 0.36234402],
    [0.85952586, 0.33905327, 0.36301129],
    [0.85754536, 0.33574168, 0.36373567],
    [0.855514, 0.33247568, 0.36451271],
    [0.85344392, 0.32924217, 0.36533344],
    [0.8513284, 0.32604977, 0.36620106],
    [0.84916723, 0.32289973, 0.36711424],
    [0.84696243, 0.31979068, 0.36806976],
    [0.84470627, 0.31673295, 0.36907066],
    [0.84240761, 0.31371695, 0.37010969],
    [0.84005337, 0.31075974, 0.37119284],
    [0.83765537, 0.30784814, 0.3723105],
    [0.83520234, 0.30499724, 0.37346726],
    [0.83270291, 0.30219766, 0.37465552],
    [0.83014895, 0.29946081, 0.37587769],
    [0.82754694, 0.29677989, 0.37712733],
    [0.82489111, 0.29416352, 0.37840532],
    [0.82218644, 0.29160665, 0.37970606],
    [0.81942908, 0.28911553, 0.38102921],
    [0.81662276, 0.28668665, 0.38236999],
    [0.81376555, 0.28432371, 0.383727],
    [0.81085964, 0.28202508, 0.38509649],
    [0.8079055, 0.27979128, 0.38647583],
    [0.80490309, 0.27762348, 0.3878626],
    [0.80185613, 0.2755178, 0.38925253],
    [0.79876118, 0.27347974, 0.39064559],
    [0.79562644, 0.27149928, 0.39203532],
    [0.79244362, 0.2695883, 0.39342447],
    [0.78922456, 0.26773176, 0.3948046],
    [0.78596161, 0.26594053, 0.39617873],
    [0.7826624, 0.26420493, 0.39754146],
    [0.77932717, 0.26252522, 0.39889102],
    [0.77595363, 0.2609049, 0.4002279],
    [0.77254999, 0.25933319, 0.40154704],
    [0.76911107, 0.25781758, 0.40284959],
    [0.76564158, 0.25635173, 0.40413341],
    [0.76214598, 0.25492998, 0.40539471],
    [0.75861834, 0.25356035, 0.40663694],
    [0.75506533, 0.25223402, 0.40785559],
    [0.75148963, 0.2509473, 0.40904966],
    [0.74788835, 0.24970413, 0.41022028],
    [0.74426345, 0.24850191, 0.41136599],
    [0.74061927, 0.24733457, 0.41248516],
    [0.73695678, 0.24620072, 0.41357737],
    [0.73327278, 0.24510469, 0.41464364],
    [0.72957096, 0.24404127, 0.4156828],
    [0.72585394, 0.24300672, 0.41669383],
    [0.7221226, 0.24199971, 0.41767651],
    [0.71837612, 0.24102046, 0.41863486],
    [0.71463236, 0.24004289, 0.41956983],
    [0.7108932, 0.23906316, 0.42048681],
    [0.70715842, 0.23808142, 0.42138647],
    [0.70342811, 0.2370976, 0.42226844],
    [0.69970218, 0.23611179, 0.42313282],
    [0.69598055, 0.2351247, 0.42397678],
    [0.69226314, 0.23413578, 0.42480327],
    [0.68854988, 0.23314511, 0.42561234],
    [0.68484064, 0.23215279, 0.42640419],
    [0.68113541, 0.23115942, 0.42717615],
    [0.67743412, 0.23016472, 0.42792989],
    [0.67373662, 0.22916861, 0.42866642],
    [0.67004287, 0.22817117, 0.42938576],
    [0.66635279, 0.22717328, 0.43008427],
    [0.66266621, 0.22617435, 0.43076552],
    [0.65898313, 0.22517434, 0.43142956],
    [0.65530349, 0.22417381, 0.43207427],
    [0.65162696, 0.22317307, 0.4327001],
    [0.64795375, 0.22217149, 0.43330852],
    [0.64428351, 0.22116972, 0.43389854],
    [0.64061624, 0.22016818, 0.43446845],
    [0.63695183, 0.21916625, 0.43502123],
    [0.63329016, 0.21816454, 0.43555493],
    [0.62963102, 0.2171635, 0.43606881],
    [0.62597451, 0.21616235, 0.43656529],
    [0.62232019, 0.21516239, 0.43704153],
    [0.61866821, 0.21416307, 0.43749868],
    [0.61501835, 0.21316435, 0.43793808],
    [0.61137029, 0.21216761, 0.4383556],
    [0.60772426, 0.2111715, 0.43875552],
    [0.60407977, 0.21017746, 0.43913439],
    [0.60043678, 0.20918503, 0.43949412],
    [0.59679524, 0.20819447, 0.43983393],
    [0.59315487, 0.20720639, 0.44015254],
    [0.58951566, 0.20622027, 0.44045213],
    [0.58587715, 0.20523751, 0.44072926],
    [0.5822395, 0.20425693, 0.44098758],
    [0.57860222, 0.20328034, 0.44122241],
    [0.57496549, 0.20230637, 0.44143805],
    [0.57132875, 0.20133689, 0.4416298],
    [0.56769215, 0.20037071, 0.44180142],
    [0.5640552, 0.19940936, 0.44194923],
    [0.56041794, 0.19845221, 0.44207535],
    [0.55678004, 0.1975, 0.44217824],
    [0.55314129, 0.19655316, 0.44225723],
    [0.54950166, 0.19561118, 0.44231412],
    [0.54585987, 0.19467771, 0.44234111],
    [0.54221157, 0.19375869, 0.44233698],
    [0.5385549, 0.19285696, 0.44229959],
    [0.5348913, 0.19197036, 0.44222958],
    [0.53122177, 0.1910974, 0.44212735],
    [0.52754464, 0.19024042, 0.44199159],
    [0.52386353, 0.18939409, 0.44182449],
    [0.52017476, 0.18856368, 0.44162345],
    [0.51648277, 0.18774266, 0.44139128],
    [0.51278481, 0.18693492, 0.44112605],
    [0.50908361, 0.18613639, 0.4408295],
    [0.50537784, 0.18534893, 0.44050064],
    [0.50166912, 0.18457008, 0.44014054],
    [0.49795686, 0.18380056, 0.43974881],
    [0.49424218, 0.18303865, 0.43932623],
    [0.49052472, 0.18228477, 0.43887255],
    [0.48680565, 0.1815371, 0.43838867],
    [0.48308419, 0.18079663, 0.43787408],
    [0.47936222, 0.18006056, 0.43733022],
    [0.47563799, 0.17933127, 0.43675585],
    [0.47191466, 0.17860416, 0.43615337],
    [0.46818879, 0.17788392, 0.43552047],
    [0.46446454, 0.17716458, 0.43486036],
    [0.46073893, 0.17645017, 0.43417097],
    [0.45701462, 0.17573691, 0.43345429],
    [0.45329097, 0.17502549, 0.43271025],
    [0.44956744, 0.17431649, 0.4319386],
    [0.44584668, 0.17360625, 0.43114133],
    [0.44212538, 0.17289906, 0.43031642],
    [0.43840678, 0.17219041, 0.42946642],
    [0.43469046, 0.17148074, 0.42859124],
    [0.4309749, 0.17077192, 0.42769008],
    [0.42726297, 0.17006003, 0.42676519],
    [0.42355299, 0.16934709, 0.42581586],
    [0.41984535, 0.16863258, 0.42484219],
    [0.41614149, 0.16791429, 0.42384614],
    [0.41244029, 0.16719372, 0.42282661],
    [0.40874177, 0.16647061, 0.42178429],
    [0.40504765, 0.16574261, 0.42072062],
    [0.401357, 0.16501079, 0.41963528],
    [0.397669, 0.16427607, 0.418528],
    [0.39398585, 0.16353554, 0.41740053],
    [0.39030735, 0.16278924, 0.41625344],
    [0.3866314, 0.16203977, 0.41508517],
    [0.38295904, 0.16128519, 0.41389849],
    [0.37928736, 0.16052483, 0.41270599],
    [0.37562649, 0.15974704, 0.41151182],
    [0.37197803, 0.15895049, 0.41031532],
    [0.36833779, 0.15813871, 0.40911916],
    [0.36470944, 0.15730861, 0.40792149],
    [0.36109117, 0.15646169, 0.40672362],
    [0.35748213, 0.15559861, 0.40552633],
    [0.353885, 0.15471714, 0.40432831],
    [0.35029682, 0.15381967, 0.4031316],
    [0.34671861, 0.1529053, 0.40193587],
    [0.34315191, 0.15197275, 0.40074049],
    [0.33959331, 0.15102466, 0.3995478],
    [0.33604378, 0.15006017, 0.39835754],
    [0.33250529, 0.14907766, 0.39716879],
    [0.32897621, 0.14807831, 0.39598285],
    [0.3254559, 0.14706248, 0.39480044],
    [0.32194567, 0.14602909, 0.39362106],
    [0.31844477, 0.14497857, 0.39244549],
    [0.31494974, 0.14391333, 0.39127626],
    [0.31146605, 0.14282918, 0.39011024],
    [0.30798857, 0.1417297, 0.38895105],
    [0.30451661, 0.14061515, 0.38779953],
    [0.30105136, 0.13948445, 0.38665531],
    [0.2975886, 0.1383403, 0.38552159],
    [0.29408557, 0.13721193, 0.38442775]
]

export const crest = [
    [0.6468274, 0.80289262, 0.56592265],
    [0.64233318, 0.80081141, 0.56639461],
    [0.63791969, 0.7987162, 0.56674976],
    [0.6335316, 0.79661833, 0.56706128],
    [0.62915226, 0.7945212, 0.56735066],
    [0.62477862, 0.79242543, 0.56762143],
    [0.62042003, 0.79032918, 0.56786129],
    [0.61606327, 0.78823508, 0.56808666],
    [0.61171322, 0.78614216, 0.56829092],
    [0.60736933, 0.78405055, 0.56847436],
    [0.60302658, 0.78196121, 0.56864272],
    [0.59868708, 0.77987374, 0.56879289],
    [0.59435366, 0.77778758, 0.56892099],
    [0.59001953, 0.77570403, 0.56903477],
    [0.58568753, 0.77362254, 0.56913028],
    [0.58135593, 0.77154342, 0.56920908],
    [0.57702623, 0.76946638, 0.56926895],
    [0.57269165, 0.76739266, 0.5693172],
    [0.56835934, 0.76532092, 0.56934507],
    [0.56402533, 0.76325185, 0.56935664],
    [0.55968429, 0.76118643, 0.56935732],
    [0.55534159, 0.75912361, 0.56934052],
    [0.55099572, 0.75706366, 0.56930743],
    [0.54664626, 0.75500662, 0.56925799],
    [0.54228969, 0.75295306, 0.56919546],
    [0.53792417, 0.75090328, 0.56912118],
    [0.53355172, 0.74885687, 0.5690324],
    [0.52917169, 0.74681387, 0.56892926],
    [0.52478243, 0.74477453, 0.56881287],
    [0.52038338, 0.74273888, 0.56868323],
    [0.5159739, 0.74070697, 0.56854039],
    [0.51155269, 0.73867895, 0.56838507],
    [0.50711872, 0.73665492, 0.56821764],
    [0.50267118, 0.73463494, 0.56803826],
    [0.49822926, 0.73261388, 0.56785146],
    [0.49381422, 0.73058524, 0.56767484],
    [0.48942421, 0.72854938, 0.56751036],
    [0.48505993, 0.72650623, 0.56735752],
    [0.48072207, 0.72445575, 0.56721583],
    [0.4764113, 0.72239788, 0.56708475],
    [0.47212827, 0.72033258, 0.56696376],
    [0.46787361, 0.71825983, 0.56685231],
    [0.46364792, 0.71617961, 0.56674986],
    [0.45945271, 0.71409167, 0.56665625],
    [0.45528878, 0.71199595, 0.56657103],
    [0.45115557, 0.70989276, 0.5664931],
    [0.44705356, 0.70778212, 0.56642189],
    [0.44298321, 0.70566406, 0.56635683],
    [0.43894492, 0.70353863, 0.56629734],
    [0.43493911, 0.70140588, 0.56624286],
    [0.43096612, 0.69926587, 0.5661928],
    [0.42702625, 0.69711868, 0.56614659],
    [0.42311977, 0.69496438, 0.56610368],
    [0.41924689, 0.69280308, 0.56606355],
    [0.41540778, 0.69063486, 0.56602564],
    [0.41160259, 0.68845984, 0.56598944],
    [0.40783143, 0.68627814, 0.56595436],
    [0.40409434, 0.68408988, 0.56591994],
    [0.40039134, 0.68189518, 0.56588564],
    [0.39672238, 0.6796942, 0.56585103],
    [0.39308781, 0.67748696, 0.56581581],
    [0.38949137, 0.67527276, 0.56578084],
    [0.38592889, 0.67305266, 0.56574422],
    [0.38240013, 0.67082685, 0.56570561],
    [0.37890483, 0.66859548, 0.56566462],
    [0.37544276, 0.66635871, 0.56562081],
    [0.37201365, 0.66411673, 0.56557372],
    [0.36861709, 0.6618697, 0.5655231],
    [0.36525264, 0.65961782, 0.56546873],
    [0.36191986, 0.65736125, 0.56541032],
    [0.35861935, 0.65509998, 0.56534768],
    [0.35535621, 0.65283302, 0.56528211],
    [0.35212361, 0.65056188, 0.56521171],
    [0.34892097, 0.64828676, 0.56513633],
    [0.34574785, 0.64600783, 0.56505539],
    [0.34260357, 0.64372528, 0.5649689],
    [0.33948744, 0.64143931, 0.56487679],
    [0.33639887, 0.6391501, 0.56477869],
    [0.33334501, 0.63685626, 0.56467661],
    [0.33031952, 0.63455911, 0.564569],
    [0.3273199, 0.63225924, 0.56445488],
    [0.32434526, 0.62995682, 0.56433457],
    [0.32139487, 0.62765201, 0.56420795],
    [0.31846807, 0.62534504, 0.56407446],
    [0.3155731, 0.62303426, 0.56393695],
    [0.31270304, 0.62072111, 0.56379321],
    [0.30985436, 0.61840624, 0.56364307],
    [0.30702635, 0.61608984, 0.56348606],
    [0.30421803, 0.61377205, 0.56332267],
    [0.30143611, 0.61145167, 0.56315419],
    [0.29867863, 0.60912907, 0.56298054],
    [0.29593872, 0.60680554, 0.56280022],
    [0.29321538, 0.60448121, 0.56261376],
    [0.2905079, 0.60215628, 0.56242036],
    [0.28782827, 0.5998285, 0.56222366],
    [0.28516521, 0.59749996, 0.56202093],
    [0.28251558, 0.59517119, 0.56181204],
    [0.27987847, 0.59284232, 0.56159709],
    [0.27726216, 0.59051189, 0.56137785],
    [0.27466434, 0.58818027, 0.56115433],
    [0.2720767, 0.58584893, 0.56092486],
    [0.26949829, 0.58351797, 0.56068983],
    [0.26693801, 0.58118582, 0.56045121],
    [0.26439366, 0.57885288, 0.56020858],
    [0.26185616, 0.57652063, 0.55996077],
    [0.25932459, 0.57418919, 0.55970795],
    [0.25681303, 0.57185614, 0.55945297],
    [0.25431024, 0.56952337, 0.55919385],
    [0.25180492, 0.56719255, 0.5589305],
    [0.24929311, 0.56486397, 0.5586654],
    [0.24678356, 0.56253666, 0.55839491],
    [0.24426587, 0.56021153, 0.55812473],
    [0.24174022, 0.55788852, 0.55785448],
    [0.23921167, 0.55556705, 0.55758211],
    [0.23668315, 0.55324675, 0.55730676],
    [0.23414742, 0.55092825, 0.55703167],
    [0.23160473, 0.54861143, 0.5567573],
    [0.22905996, 0.54629572, 0.55648168],
    [0.22651648, 0.54398082, 0.5562029],
    [0.22396709, 0.54166721, 0.55592542],
    [0.22141221, 0.53935481, 0.55564885],
    [0.21885269, 0.53704347, 0.55537294],
    [0.21629986, 0.53473208, 0.55509319],
    [0.21374297, 0.53242154, 0.5548144],
    [0.21118255, 0.53011166, 0.55453708],
    [0.2086192, 0.52780237, 0.55426067],
    [0.20605624, 0.52549322, 0.55398479],
    [0.20350004, 0.5231837, 0.55370601],
    [0.20094292, 0.52087429, 0.55342884],
    [0.19838567, 0.51856489, 0.55315283],
    [0.19582911, 0.51625531, 0.55287818],
    [0.19327413, 0.51394542, 0.55260469],
    [0.19072933, 0.51163448, 0.5523289],
    [0.18819045, 0.50932268, 0.55205372],
    [0.18565609, 0.50701014, 0.55177937],
    [0.18312739, 0.50469666, 0.55150597],
    [0.18060561, 0.50238204, 0.55123374],
    [0.178092, 0.50006616, 0.55096224],
    [0.17558808, 0.49774882, 0.55069118],
    [0.17310341, 0.49542924, 0.5504176],
    [0.17063111, 0.49310789, 0.55014445],
    [0.1681728, 0.49078458, 0.54987159],
    [0.1657302, 0.48845913, 0.54959882],
    [0.16330517, 0.48613135, 0.54932605],
    [0.16089963, 0.48380104, 0.54905306],
    [0.15851561, 0.48146803, 0.54877953],
    [0.15615526, 0.47913212, 0.54850526],
    [0.15382083, 0.47679313, 0.54822991],
    [0.15151471, 0.47445087, 0.54795318],
    [0.14924112, 0.47210502, 0.54767411],
    [0.1470032, 0.46975537, 0.54739226],
    [0.14480101, 0.46740187, 0.54710832],
    [0.14263736, 0.46504434, 0.54682188],
    [0.14051521, 0.46268258, 0.54653253],
    [0.13843761, 0.46031639, 0.54623985],
    [0.13640774, 0.45794558, 0.5459434],
    [0.13442887, 0.45556994, 0.54564272],
    [0.1325044, 0.45318928, 0.54533736],
    [0.13063777, 0.4508034, 0.54502674],
    [0.12883252, 0.44841211, 0.5447104],
    [0.12709242, 0.44601517, 0.54438795],
    [0.1254209, 0.44361244, 0.54405855],
    [0.12382162, 0.44120373, 0.54372156],
    [0.12229818, 0.43878887, 0.54337634],
    [0.12085453, 0.4363676, 0.54302253],
    [0.11949938, 0.43393955, 0.54265715],
    [0.11823166, 0.43150478, 0.54228104],
    [0.11705496, 0.42906306, 0.54189388],
    [0.115972, 0.42661431, 0.54149449],
    [0.11498598, 0.42415835, 0.54108222],
    [0.11409965, 0.42169502, 0.54065622],
    [0.11331533, 0.41922424, 0.5402155],
    [0.11263542, 0.41674582, 0.53975931],
    [0.1120615, 0.4142597, 0.53928656],
    [0.11159738, 0.41176567, 0.53879549],
    [0.11125248, 0.40926325, 0.53828203],
    [0.11101698, 0.40675289, 0.53774864],
    [0.11089152, 0.40423445, 0.53719455],
    [0.11085121, 0.4017095, 0.53662425],
    [0.11087217, 0.39917938, 0.53604354],
    [0.11095515, 0.39664394, 0.53545166],
    [0.11110676, 0.39410282, 0.53484509],
    [0.11131735, 0.39155635, 0.53422678],
    [0.11158595, 0.38900446, 0.53359634],
    [0.11191139, 0.38644711, 0.5329534],
    [0.11229224, 0.38388426, 0.53229748],
    [0.11273683, 0.38131546, 0.53162393],
    [0.11323438, 0.37874109, 0.53093619],
    [0.11378271, 0.37616112, 0.53023413],
    [0.11437992, 0.37357557, 0.52951727],
    [0.11502681, 0.37098429, 0.52878396],
    [0.11572661, 0.36838709, 0.52803124],
    [0.11646936, 0.36578429, 0.52726234],
    [0.11725299, 0.3631759, 0.52647685],
    [0.1180755, 0.36056193, 0.52567436],
    [0.1189438, 0.35794203, 0.5248497],
    [0.11984752, 0.35531657, 0.52400649],
    [0.1207833, 0.35268564, 0.52314492],
    [0.12174895, 0.35004927, 0.52226461],
    [0.12274959, 0.34740723, 0.52136104],
    [0.12377809, 0.34475975, 0.52043639],
    [0.12482961, 0.34210702, 0.51949179],
    [0.125902, 0.33944908, 0.51852688],
    [0.12699998, 0.33678574, 0.51753708],
    [0.12811691, 0.33411727, 0.51652464],
    [0.12924811, 0.33144384, 0.51549084],
    [0.13039157, 0.32876552, 0.51443538],
    [0.13155228, 0.32608217, 0.51335321],
    [0.13272282, 0.32339407, 0.51224759],
    [0.13389954, 0.32070138, 0.51111946],
    [0.13508064, 0.31800419, 0.50996862],
    [0.13627149, 0.31530238, 0.50878942],
    [0.13746376, 0.31259627, 0.50758645],
    [0.13865499, 0.30988598, 0.50636017],
    [0.13984364, 0.30717161, 0.50511042],
    [0.14103515, 0.30445309, 0.50383119],
    [0.14222093, 0.30173071, 0.50252813],
    [0.14339946, 0.2990046, 0.50120127],
    [0.14456941, 0.29627483, 0.49985054],
    [0.14573579, 0.29354139, 0.49847009],
    [0.14689091, 0.29080452, 0.49706566],
    [0.1480336, 0.28806432, 0.49563732],
    [0.1491628, 0.28532086, 0.49418508],
    [0.15028228, 0.28257418, 0.49270402],
    [0.15138673, 0.27982444, 0.49119848],
    [0.15247457, 0.27707172, 0.48966925],
    [0.15354487, 0.2743161, 0.48811641],
    [0.15459955, 0.27155765, 0.4865371],
    [0.15563716, 0.26879642, 0.4849321],
    [0.1566572, 0.26603191, 0.48330429],
    [0.15765823, 0.26326032, 0.48167456],
    [0.15862147, 0.26048295, 0.48005785],
    [0.15954301, 0.25770084, 0.47845341],
    [0.16043267, 0.25491144, 0.4768626],
    [0.16129262, 0.25211406, 0.4752857],
    [0.1621119, 0.24931169, 0.47372076],
    [0.16290577, 0.24649998, 0.47217025],
    [0.16366819, 0.24368054, 0.47063302],
    [0.1644021, 0.24085237, 0.46910949],
    [0.16510882, 0.2380149, 0.46759982],
    [0.16579015, 0.23516739, 0.46610429],
    [0.1664433, 0.2323105, 0.46462219],
    [0.16707586, 0.22944155, 0.46315508],
    [0.16768475, 0.22656122, 0.46170223],
    [0.16826815, 0.22366984, 0.46026308],
    [0.16883174, 0.22076514, 0.45883891],
    [0.16937589, 0.21784655, 0.45742976],
    [0.16990129, 0.21491339, 0.45603578],
    [0.1704074, 0.21196535, 0.45465677],
    [0.17089473, 0.20900176, 0.4532928],
    [0.17136819, 0.20602012, 0.45194524],
    [0.17182683, 0.20302012, 0.45061386],
    [0.17227059, 0.20000106, 0.44929865],
    [0.17270583, 0.19695949, 0.44800165],
    [0.17313804, 0.19389201, 0.44672488],
    [0.17363177, 0.19076859, 0.44549087]
]
