// Modified from original by Julien P. Nicolas (FEVer), plus additional changes
// for fev2r integration
//
// Source: https://github.com/cambecc/earth/blob/master/public/libs/earth/1.0.0/
// License information under 'earth' entry in VENDORED

import { rgb } from 'd3-color';
import { scaleLog, scaleLinear } from 'd3-scale';
import { interpolateRgb } from 'd3-interpolate';

function segmentedColorScale(segments, method) {
  let domains = [], ranges = [];
  let r, g, b;
  for (let i = 0; i <= segments.length - 1; i++) {
    domains.push(segments[i][0]);
    [r, g, b] = segments[i][1];
    ranges.push(rgb(r, g, b));
  }
  let scale = method === 'log' ? scaleLog() : scaleLinear();
  scale
    .domain(domains)
    .interpolate(interpolateRgb)
    .range(ranges);
  return function(point) {
    let c = rgb(scale(point));
    return [c.r, c.g, c.b];
  };
}

var τ = 2 * Math.PI;
var BOUNDARY = 0.45;
var fadeToWhite = colorInterpolator(sinebowColor(1.0), [255, 255, 255]);

function extendedSinebowColor(i) {
  return i <= BOUNDARY ?
    sinebowColor(i / BOUNDARY) :
    fadeToWhite((i - BOUNDARY) / (1 - BOUNDARY));
}

function sinebowColor(hue) {
  var rad = hue * τ * 5/6;
  rad *= 0.75;

  var s = Math.sin(rad);
  var c = Math.cos(rad);
  var r = Math.floor(Math.max(0, -c) * 255);
  var g = Math.floor(Math.max(s, 0) * 255);
  var b = Math.floor(Math.max(c, 0, -s) * 255);
  return [r, g, b];
}

function colorInterpolator(start, end) {
  var r = start[0], g = start[1], b = start[2];
  var Δr = end[0] - r, Δg = end[1] - g, Δb = end[2] - b;
  return function(i) {
    return [
      Math.floor(r + i * Δr), Math.floor(g + i * Δg), Math.floor(b + i * Δb)
    ];
  };
}

export const wind = v => extendedSinebowColor(Math.min(v, 100) / 100);

export const temp = segmentedColorScale([
  [193,     [37, 4, 42]],
  [206,     [41, 10, 130]],
  [219,     [81, 40, 40]],
  [233.15,  [192, 37, 149]],  // -40 C/F
  [255.372, [70, 215, 215]],  // 0 F
  [273.15,  [21, 84, 187]],   // 0 C
  [275.15,  [24, 132, 14]],   // just above 0 C
  [291,     [247, 251, 59]],
  [298,     [235, 167, 21]],
  [311,     [230, 71, 39]],
  [328,     [88, 27, 67]],
]);

export const sea_surface_temp = segmentedColorScale([
  [271.35, [255, 255, 255]],
  [273.15, [ 15,  54, 208]],
  [273.25, [ 15,  54, 188]],
  [275.65, [ 15,   4, 168]],
  [281.65, [ 24, 132,  14]],
  [291.15, [247, 251,  59]],
  [295,    [235, 167,   0]],
  [299.65, [245,   0,  39]],
  [303,    [ 87,  17,   0]],
  [304.65, [238,   0, 242]],
]);

export const precip_6h = segmentedColorScale([
  [  0.0, [ 40,  81,  94]],
  [  2.5, [220, 235, 248]],
  [  8.0, [112, 153, 222]],
  [ 12.1, [ 60,  83, 188]],
  [ 18.0, [ 82,  18, 155]],
  [ 23.0, [117,   0, 151]],
  [ 40.0, [203,   0, 127]],
  [100.1, [255, 215,   2]],
  [150.0, [255, 255, 255]],
]);

export const total_cloud_water = segmentedColorScale([
  [0.0, [5, 5, 89]],
  [0.2, [255, 255, 255]],
  [0.7, [235, 235, 235]],
  [1.0, [102, 102, 102]],
]);

export const total_precipitable_water = segmentedColorScale([
  [0, [230, 165, 30]],
  [10, [120, 100, 95]],
  [20, [40, 44, 92]],
  [30, [21, 13, 193]],
  [40, [75, 63, 235]],
  [60, [25, 255, 255]],
  [70, [150, 255, 255]],
]);

export const mean_sea_level_pressure = segmentedColorScale([
  [970.0, [0, 3, 60]],
  [973.9, [9, 33, 80]],
  [977.8, [33, 71, 110]],
  [981.7, [46, 103, 139]],
  [985.6, [65, 128, 157]],
  [989.4, [87, 153, 175]],
  [993.3, [115, 178, 193]],
  [997.2, [125, 183, 194]],
  [1001.1, [163, 210, 215]],
  [1005.0, [190, 226, 227]],
  [1008.9, [212, 235, 234]],
  [1012.8, [244, 246, 243]],
  [1016.7, [253, 251, 191]],
  [1020.6, [244, 128, 131]],
  [1024.4, [230, 98, 111]],
  [1028.3, [211, 69, 92]],
  [1032.2, [188, 41, 75]],
  [1036.1, [146, 50, 52]],
  [1040.0, [73, 0, 28]],
]);

export const so2_mass = segmentedColorScale([
  [  0.80, [235, 216, 150]],
  [  3.00, [211, 113, 106]],
  [ 10.00, [187,  64, 103]],
  [ 20.00, [164,  36, 108]],
  [ 50.00, [127,   2, 116]],
  [100.00, [ 82,   0, 123]],
  [800.00, [ 23,  20,  18]],
], 'log');

export const carbon_monoxide_surface = segmentedColorScale([
  [1.0, [0, 38, 40]],
  [16.2, [26, 57, 56]],
  [31.4, [51, 78, 74]],
  [46.5, [76, 98, 92]],
  [61.7, [101, 121, 110]],
  [76.9, [127, 143, 129]],
  [92.1, [153, 166, 149]],
  [107.3, [181, 190, 169]],
  [122.5, [208, 214, 189]],
  [137.6, [234, 237, 208]],
  [152.8, [250, 249, 216]],
  [168.0, [250, 246, 210]],
  [183.2, [247, 238, 199]],
  [198.4, [244, 231, 190]],
  [213.6, [242, 224, 181]],
  [228.7, [240, 216, 172]],
  [243.9, [238, 209, 164]],
  [259.1, [237, 201, 156]],
  [274.3, [235, 193, 149]],
  [289.5, [234, 185, 142]],
  [304.6, [233, 177, 136]],
  [319.8, [231, 169, 130]],
  [335.0, [230, 161, 125]],
  [350.2, [228, 152, 121]],
  [365.4, [227, 144, 118]],
  [380.6, [225, 135, 115]],
  [395.7, [223, 127, 113]],
  [410.9, [220, 118, 111]],
  [426.1, [218, 109, 110]],
  [441.3, [214, 100, 110]],
  [456.5, [211, 91, 110]],
  [471.7, [207, 82, 110]],
  [486.8, [203, 73, 111]],
  [502.0, [198, 64, 113]],
  [517.2, [193, 54, 114]],
  [532.4, [187, 45, 116]],
  [547.6, [180, 35, 119]],
  [562.7, [173, 25, 121]],
  [577.9, [165, 13, 124]],
  [593.1, [156, 0, 127]],
  [608.3, [144, 0, 130]],
  [623.5, [131, 0, 134]],
  [638.7, [119, 0, 136]],
  [653.8, [106, 0, 139]],
  [669.0, [93, 0, 140]],
  [684.2, [81, 0, 141]],
  [699.4, [68, 0, 140]],
  [714.6, [56, 0, 138]],
  [729.8, [46, 2, 135]],
  [744.9, [37, 4, 130]],
  [760.1, [31, 5, 124]],
  [775.3, [27, 6, 118]],
  [790.5, [24, 8, 110]],
  [805.7, [24, 9, 103]],
  [820.8, [24, 9, 95]],
  [836.0, [24, 10, 87]],
  [851.2, [24, 10, 79]],
  [866.4, [23, 10, 71]],
  [881.6, [23, 10, 63]],
  [896.8, [23, 9, 56]],
  [911.9, [22, 8, 48]],
  [927.1, [21, 6, 41]],
  [942.3, [19, 5, 34]],
  [957.5, [16, 4, 27]],
  [972.7, [11, 2, 21]],
  [987.9, [5, 1, 10]],
  [1000.0, [0, 0, 0]],
], 'log');

export const dust_mass = segmentedColorScale([
  [0.05,[100,75,30]],
  [0.05,[154,109,49]],
  [0.1, [175,120,55]],
  [0.5, [191,135,61]],
  [1.0, [216,161,134]],
  [1.4, [228,175,110]],
  [3.0,[252,185,95]],
  [5.0, [254, 198, 90]],
  [7.0, [254, 192, 80]],
  [10.0, [254, 185, 72]],
  [15.0, [254, 177, 64]],
  [20.0, [253, 170, 57]],
  [25.1, [253, 162, 50]],
  [29.2, [251, 155, 45]],
  [34.0, [249, 147, 40]],
  [39.7, [247, 140, 35]],
  [46.3, [244, 132, 31]],
  [53.9, [241, 125, 27]],
  [62.9, [237, 118, 23]],
  [73.3, [232, 111, 20]],
  [85.5, [228, 104, 16]],
  [99.6, [222, 97, 13]],
  [116.1, [216, 91, 10]],
  [135.4, [210, 85, 8]],
  [157.8, [203, 79, 6]],
  [184.0, [195, 74, 4]],
  [214.5, [187, 69, 4]],
  [250.1, [178, 65, 3]],
  [291.5, [170, 60, 3]],
  [339.8, [160, 56, 4]],
  [396.2, [151, 53, 4]],
  [461.8, [142, 49, 4]],
  [538.4, [133, 46, 5]],
  [627.6, [124, 43, 5]],
  [731.7, [114, 41, 6]],
  [853.0, [105, 38, 6]],
  [900.0, [102, 37, 6]],
], 'log');

export const total_column_ozone = segmentedColorScale([
  [200.0, [255,255,255]],
  [220.0, [252,220,197]], //baselineOzone Level according to NASA
  [260.5,[196,206,10]],
  [300,[84,194,9]],
  [320,[8,168,26]],
  [360,[7,137,69]],
  [400,[5,106,106]],
  [450, [8,66,165]],
  [500.0,[46,4,76]],
  [600.0, [0,0,0]],
]);

export const currents = segmentedColorScale([
  [0,[255, 255, 255]],
  [0.15,[186, 206, 247]],
  [0.4,[123, 159, 221]],
  [0.65,[68, 114, 182]],
  [1.0,[24, 71, 133]],
  [1.5,[0, 31, 79]],
]);
