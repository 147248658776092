export const alternate_temp =
[
// [255,255,255],
// [255,163,163],
// [255,143,77],
// [137,162,48],
// [37,181,23],
// [34,115,115],
// [29,75,226],
// [119,28,217],
// [77,11,81],
// [0,0,0]

[0,0,0],
[77,11,81],
[119,28,217],
[29,75,126],
[34,115,115],
// [79,131,74],
[45,121,38],
[137,162,48],
[197,125,91],
[227,157,157],
[241,248,177],
[255,255,255]

];
